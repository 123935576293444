import qs from 'qs'
import { getOplzClient } from './apiClient'

const baseUrl = '/facets'

const getFacets = async (facets, includeFirstStartMomentDateFilter, includeFinalStartMomentDateFilter) => {
    const client = await getOplzClient()
    return client
        .get(`${baseUrl}`, {
            params: {
                facets,
                includeFirstStartMomentDateFilter,
                includeFinalStartMomentDateFilter
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            }
        })
        .then(response => response?.data)
}

export default {
    getFacets
}
