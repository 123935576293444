import axios from 'axios'
import { getOplzClient } from './apiClient'
import ParamsUtils from '../utils/ParamUtils'

const baseUrl = '/users'
const FetchSearchCancelToken = axios.CancelToken
let fetchSearchCancel

/**
 * Get current user
 * 
 * @param {boolean} [includeDelegation] include delegation details
 * @param {boolean} [includeTeamLeaderName] 
 * @param {boolean} [checkNOABundle] 
 * @param {('Assortments' | 'Account.Settings' | 'CompanyTeamLeaders' | 'WishListItems')[]} [includes] 
 * @returns {Promise<any>}
 */
const getCurrentUser = async ({includeDelegation, includeTeamLeaderName, checkNOABundle, includes}) => {
    const client = await getOplzClient()

    const getParams = ParamsUtils.buildGETParams({
        includeDelegation: !!includeDelegation,
        includeTeamLeaderName: !!includeTeamLeaderName,
        checkNOABundle: !!checkNOABundle,
        include: (includes || []).join(',')
    })

    return client.get(`${baseUrl}/getCurrentUser?${getParams}`).then(response => {
        return response?.data
    })
}

const getCountries = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/getCountryOptions`).then(response => {
        return response?.data
    })
}

const searchUsers = async data => {
    const client = await getOplzClient()
    return client
        .post(`${baseUrl}/search`, data, { 
            cancelToken: new FetchSearchCancelToken(c => {
            // An executor function receives a cancel function as a parameter
                fetchSearchCancel = c
            })
        })
        .then(response => {
            return response?.data
        })
}

const searchUsersBasicInfo = async data => {
    const client = await getOplzClient()
    return client
        .post(`${baseUrl}/search-basic-info`, data, {
            cancelToken: new FetchSearchCancelToken(c => {
                // An executor function receives a cancel function as a parameter
                fetchSearchCancel = c
            })
        })
        .then(response => {
            return response?.data
        })
}

const searchUserIds = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/user-ids/search`, data).then(response => {
        return response?.data
    })
}

const getCompanyTeamleaders = async data => {
    const client = await getOplzClient()
    return client
        .post(`${baseUrl}/company-team-leaders`, data, {
            cancelToken: new FetchSearchCancelToken(c => {
                // An executor function receives a cancel function as a parameter
                fetchSearchCancel = c
            })
        })
        .then(response => {
            return response?.data
        })
}

const getCoordinators = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/getAccountCoordinators`).then(response => {
        return response?.data
    })
}

const updateUser = async data => {
    const client = await getOplzClient()
    return client.put(`${baseUrl}`, data).then(response => {
        return response?.data
    })
}

const postLegalAgreementConsent = (userId) => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.post(
                `${baseUrl}/${userId}/legal-agreement-consent?allow-data-processing=true`
            )
                .then(response => {
                    resolve(response)
                })
                .catch(err => reject(err))
        })
    })
}

const getNotifications = async (userId, take, includeRead) => {
    let queryString = ''
    if (take) queryString += `?take=${take}`
    const includeReadChar = queryString === '' ? '?' : '&'
    queryString += `${includeReadChar}includeRead=${includeRead}`
    const url = `${baseUrl}/${userId}/notifications${queryString}`
    const client = await getOplzClient()
    return client.get(url)
}

const setNotificationAsRead = async (userId, notificationId) => {
    const url = `${baseUrl}/${userId}/notifications/${notificationId}/read`
    const client = await getOplzClient()
    return client.post(url)
}

const postUnavailableCourse = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/courseunavailable`, data).then(response => {
        return response?.data
    })
}

const postFollowCourse = async data => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/followcourse`, data).then(response => {
        return response?.data
    })
}

const getActivityCount = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/activityCount`).then(response => {
        return response?.data
    })
}

const getSecondApprovers = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/secondApprovers`).then(response => {
        return response?.data
    })
}

const downloadPersonalData = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/downloadPersonalData`).then(response => {
        return response
    })
}

const fetchUserJobNames = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/job-names`).then(response => {
        return response.data
    })
}

const cancelFetchSearch = () => {
    if (fetchSearchCancel !== undefined) {
        fetchSearchCancel()
    }
}

const getAssortments = async () => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/get-assortments`).then(response => {
        return response?.data
    })
}

export default {
    getCurrentUser,
    getCountries,
    searchUsers,
    searchUserIds,
    getCompanyTeamleaders,
    getCoordinators,
    updateUser,
    postLegalAgreementConsent,
    getNotifications,
    setNotificationAsRead,
    postUnavailableCourse,
    postFollowCourse,
    getActivityCount,
    getSecondApprovers,
    downloadPersonalData,
    fetchUserJobNames,
    cancelFetchSearch,
    searchUsersBasicInfo,
    getAssortments
}
