<template>
    <div>
        <lightbox-component is-visible :show-close-button="false">
            <transition name="fade" mode="out-in">
                <loader-component v-if="isLoading" overlay="white" color="primary" />
            </transition>

            <h3>Deze webapplicatie maakt gebruik van cookies</h3>
            <p>
                We gebruiken cookies om jouw toegang te faciliteren en AI ondersteund zoeken
                mogelijk te maken. Ook beveiligen wij de login met recaptcha, waarvoor een cookie
                wordt geplaatst. Wij gebruiken cookies om web analytics te verzamelen en jouw mening
                uit te vragen. Zowel de analytics en de polls verzamelen wij anoniem met het doel om
                het gebruik van de applicatie te meten voor rapportagedoeleinden richting
                opdrachtgevers en voor verbetering van het platform. Hiervoor gebruiken wij tooling
                van derde partijen. Hoewel wij data niet herleidbaar opslaan, zouden deze partijen
                desalniettemin jouw cookie gegevens kunnen combineren met andere informatie die je
                aan ze hebt verstrekt of die ze hebben verzameld op basis van jouw gebruik van hun
                services. De geplaatste cookies zijn noodzakelijk om onze diensten aan jou en onze
                opdrachtgever (meestal jouw werkgever) te kunnen uitvoeren.
            </p>

            <form @submit.prevent="submitConsents">
                <recess-checkbox-input
                    :default-checked="true"
                    disabled
                    data-test="legal-agreement"
                    label-text="Noodzakelijke cookies"
                />
                <recess-checkbox-input
                    :default-checked="true"
                    disabled
                    label-text="Analytische cookies"
                />

                <div class="u-position-relative">
                    <transition name="fade" mode="out-in">
                        <loader-component
                            v-if="isLoadingLegalAgreements"
                            overlay="white"
                            color="primary"
                            variant="small"
                        />
                    </transition>

                    <div v-if="legalAgreements.length" class="mt-3">
                        <div v-for="(agreement, index) in legalAgreements" :key="index">
                            <a :href="agreement.url" target="_blank" class="underline">
                                <u>Details tonen van {{ agreement.name }} &gt;</u>
                            </a>
                        </div>
                    </div>
                </div>

                <recess-divider />

                <div class="d-flex justify-content-end">
                    <recess-button
                        variant="primary"
                        title="Doorgaan"
                        type="submit"
                        data-test="submit-legal-agreement"
                    />
                </div>
            </form>

            <span v-if="errors" class="o-error-text mt-3">{{ errors }}</span>
        </lightbox-component>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import LightboxComponent from '../LightboxComponent/LightboxComponent.vue'
import legalAgreementsClient from '../../../api/legalAgreementsClient'
import userClient from '../../../api/userClient'

export default {
    components: {
        LoaderComponent,
        LightboxComponent
    },
    data() {
        return {
            isLoading: false,
            isLoadingLegalAgreements: false,
            errors: null,
            legalAgreements: []
        }
    },
    computed: {
        ...mapGetters('userModule', ['legalAgreementAcceptedDate']),
        ...mapState('userModule', ['user'])
    },
    mounted() {
        this.fetchLegalAgreements()
    },
    methods: {
        async fetchLegalAgreements() {
            this.isLoadingLegalAgreements = true

            try {
                // fetch the legal agreements and the DPA value
                this.legalAgreements = await legalAgreementsClient.getLegalAgreements(true)
            } finally {
                this.isLoadingLegalAgreements = false
            }
        },
        redirectToHome() {
            const redirectPath = sessionStorage.getItem('oidc_active_route')
            return this.$router.replace(redirectPath || { name: 'home' })
        },
        async submitConsents() {
            if (this.legalAgreementAcceptedDate) {
                await this.redirectToHome()
            }

            await this.postLegalAgreementConsent()
        },
        async postLegalAgreementConsent() {
            try {
                this.isLoading = true

                const response = await userClient.postLegalAgreementConsent(this.user.userId)
                if (response?.status === 200) {
                    this.redirectToHome()
                }
            } catch (error) {
                const response = error?.response?.data ?? null
                if (response && response.message) {
                    this.errors = response.message
                } else {
                    this.errors = 'Er is een fout opgetreden. Probeer het later opnieuw.'
                }
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>
