<template>
  <div>
    <background-component :x-small-image="background" />
    <legal-agreement-form-component v-if="isUserFetched" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BackgroundComponent from '../../atoms/BackgroundComponent/BackgroundComponent.vue'
import LegalAgreementFormComponent from '../../molecules/LegalAgreementFormComponent/LegalAgreementFormComponent.vue'
import background from '../../../assets/images/login-background.jpg'
import legalAgreementsPerimeter from '../../../perimeters/legalAgreementsPerimeter'

export default {
    perimeters: [legalAgreementsPerimeter],
    components: {
        BackgroundComponent,
        LegalAgreementFormComponent
    },
    props: {
        isLegalAgreementLightboxVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            background
        }
    },
    computed: {
        ...mapState('userModule', ['isUserFetched'])
    }
}
</script>

