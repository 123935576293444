import facetClient from "../../api/facetClient"
import * as facets from '../../constants/facetKeys'

const facetsModule = {
    namespaced: true,

    state() {
        return {
            facets: [],
            isFetchingFacets: false
        }
    },
    getters: {
        expertiseAreaItems(state) {
            return state.facets?.find(facet => facet.key === facets.SEGMENTS)
        },
        variants(state) {
            return state.facets?.find(facet => facet.key === facets.VARIANTS)
        },
        levels(state) {
            return state.facets?.find(facet => facet.key === facets.LEVELS)
        }
    },
    mutations: {
        SET_FACETS(state, newFacets) {
            state.facets = Object.keys(newFacets)?.map(key => {
                return {
                    key,
                    values: newFacets[key]?.reduce((acc, facetItem) => {
                        if(facetItem.value?.length) acc.push(facetItem.value)
                        return acc
                    }, [])
                }
            })
        },
        SET_IS_FETCHING_FACETS(state, isFetchingFacets) {
            state.isFetchingFacets = isFetchingFacets
        }
    },
    actions: {
        async fetchFacets({ commit }, { facetsToFetch, includeFirstStartMomentDateFilter, includeFinalStartMomentDateFilter}) {
            try {
                commit('SET_IS_FETCHING_FACETS', true)
                const response = await facetClient.getFacets(facetsToFetch, includeFirstStartMomentDateFilter, includeFinalStartMomentDateFilter)
                commit('SET_FACETS', response)
            } catch (error) {
                throw new Error(error)
            } finally {
                commit('SET_IS_FETCHING_FACETS', false)
            }
        }
    }
}

export default facetsModule