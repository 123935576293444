import axios from 'axios'
import { getOplzClient } from './apiClient'

const baseUrl = '/courses'
const FetchSearchCancelToken = axios.CancelToken
const FetchSearchSuggestionsCancelToken = axios.CancelToken
const SearchStartMomentsCancelToken = axios.CancelToken
let searchStartMomentsCancel
let fetchSearchCancel
let fetchSearchSuggestionsCancel

const getCourse = async id => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${id}`).then(response => {
        return response?.data
    })
}

const getPrice = (id, useTeamLeaderAssortments, assortmentId) => {
    // Setup base url
    let coursePriceUrl = `${baseUrl}/${id}/price?useteamleaderassortments=${useTeamLeaderAssortments}`
    
    // only attach second param if there is an assortmentId
    if(assortmentId) coursePriceUrl = `${coursePriceUrl}&assortmentid=${assortmentId}` 
    
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.get(coursePriceUrl)
                .then(response => {
                    const price = response?.data
                    resolve(price)
                })
                .catch(err => reject(err))
        })
    })
}

const getStatistics = (includeFirstStartMomentDateFilter, includeFinalStartMomentDateFilter) => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.get(`${baseUrl}/statistics?includeFinalStartMomentDateFilter=${includeFinalStartMomentDateFilter}&includeFirstStartMomentDateFilter=${includeFirstStartMomentDateFilter}`)
                .then(response => {
                    const statistics = response?.data
                    resolve(statistics)
                })
                .catch(err => reject(err))
        })
    })
}

const getOrdersForCourse = id => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.get(`${baseUrl}/${id}/orders`)
                .then(response => {
                    const orders = response?.data
                    resolve(orders)
                })
                .catch(err => reject(err))
        })
    })
}

const getCourseRating = id => {
    return new Promise((resolve, reject) => {
        getOplzClient().then(c => {
            c.get(`${baseUrl}/${id}/rating`)
                .then(response => {
                    const courseRating = response?.data
                    resolve(courseRating)
                })
                .catch(err => reject(err))
        })
    })
}

const postCourseRating = async (id, rating) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/rating?rating=${rating}`).then(response => {
        return response?.data
    })
}

const patchCourseRating = async (id, rating) => {
    const client = await getOplzClient()
    return client.patch(`${baseUrl}/rating/${id}?rating=${rating}`).then(response => {
        return response?.data
    })
}

const fetchSearch = async data => {
    if (fetchSearchCancel !== undefined) {
        fetchSearchCancel()
    }

    const client = await getOplzClient()
    return client.post(`${baseUrl}/search`, data, {
        cancelToken: new FetchSearchCancelToken(c => {
            // An executor function receives a cancel function as a parameter
            fetchSearchCancel = c
        })
    })
}

const fetchSearchSuggestions = async data => {
    if (fetchSearchSuggestionsCancel !== undefined) {
        fetchSearchSuggestionsCancel()
    }

    const client = await getOplzClient()
    return client.post(`${baseUrl}/suggest`, data, {
        cancelToken: new FetchSearchSuggestionsCancelToken(c => {
            // An executor function receives a cancel function as a parameter
            fetchSearchSuggestionsCancel = c
        })
    })
}

const recommendCourse = async (id, data) => {
    const client = await getOplzClient()
    return client.post(`${baseUrl}/${id}/recommend`, data)
}

const cancelFetchSearchSuggestions = () => {
    if (fetchSearchSuggestionsCancel !== undefined) {
        fetchSearchSuggestionsCancel()
    }
}

const cancelFetchSearch = () => {
    if (fetchSearchCancel !== undefined) {
        fetchSearchCancel()
    }
}

const cancelSearchStartMoments = () => {
    if (searchStartMomentsCancel !== undefined) {
        searchStartMomentsCancel()
    }
}

const getCourseStartmoments = async (courseId, assortmentId) => {
    const client = await getOplzClient()
    return client.get(`${baseUrl}/${courseId}/startmoments/${assortmentId}`).then(response => {
        return response?.data
    })
} 

const searchStartMoments = async data => {
    if (searchStartMomentsCancel !== undefined) {
        searchStartMomentsCancel()
    }

    const client = await getOplzClient()

    return client.post(`${baseUrl}/start-moments/search`, data, {
        cancelToken: new SearchStartMomentsCancelToken(c => {
            // An executor function receives a cancel function as a parameter
            searchStartMomentsCancel = c
        })
    }).then(response => response.data)
} 

export default {
    getCourse,
    getPrice,
    getStatistics,
    getOrdersForCourse,
    getCourseRating,
    fetchSearch,
    fetchSearchSuggestions,
    cancelFetchSearch,
    cancelFetchSearchSuggestions,
    postCourseRating,
    patchCourseRating,
    recommendCourse,
    getCourseStartmoments,
    searchStartMoments,
    cancelSearchStartMoments
}
