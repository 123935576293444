<!-- eslint-disable vue/no-v-html Content is sanitized-->
<template>
    <recess-layout-static>
        <recess-divider />
        <h1>Inschrijfgegevens</h1>

        <transition name="fade" mode="out-in">
            <loader-component v-if="isLoading" overlay="white" color="primary" is-fixed />
        </transition>

        <recess-divider />

        <div v-if="enrollment && !isFetchingEnrollment">
            <cancel-enrollment-popup-component
                :show-cancel-enrollment-modal="showCancelEnrollmentModal"
                :enrollment-id="enrollment.enrollmentId"
                @close="showCancelEnrollmentModal = false"
            />

            <section>
                <h4 class="u-textcolor-primary">Gegevens inschrijving</h4>
                <table aria-label="Course name" class="w-100">
                    <tbody>
                        <tr>
                            <th scope="row" class="text-left pb-3">Ontwikkelactiviteit</th>
                            <td class="w-50 pb-3"><span v-html="enrollment.courseName"></span></td>
                        </tr>

                        <tr
                            v-if="!!enrollment.projectRequestId"
                            data-test="project-request-name-id"
                        >
                            <th scope="row" class="text-left pb-3">Project</th>
                            <td class="w-50 pb-3"><span v-html="projectRequestNameId"></span></td>
                        </tr>
                    </tbody>
                </table>

                <enrollment-price-component
                    v-if="$isAllowed('displayPricingInformation')"
                    :enrollment="enrollment"
                />

                <table aria-label="Course detail" class="w-100">
                    <tbody>
                        <template v-if="!$isAllowed('updateStartmoments', enrollment)">
                            <template v-if="acceptedStartMoment">
                                <tr data-test="accepted-start-moment">
                                    <th
                                        scope="row"
                                        class="text-left py-3 d-flex align-items-center"
                                    >
                                        <span>Definitief startmoment</span>
                                        <recess-tooltip>
                                            <recess-icon icon="check" color="green" />
                                            <template #content>
                                                <span class="d-block u-max-width-500"
                                                    >De uitvoerder van de ontwikkelactiviteit heeft
                                                    uw inschrijving voor dit startmoment
                                                    bevestigd.</span
                                                >
                                            </template>
                                        </recess-tooltip>
                                    </th>
                                    <td class="w-50 py-3">
                                        <div>
                                            {{ acceptedStartMoment.displayDate }}
                                            <span v-if="acceptedStartMoment.displayDay"
                                                >({{ acceptedStartMoment.displayDay }})</span
                                            >
                                        </div>
                                        <div>{{ acceptedStartMoment.location || '-' }}</div>
                                    </td>
                                </tr>
                                <tr v-if="acceptedStartMoment.firstEventDate">
                                    <th scope="row" class="text-left pb-3">Eerste bijeenkomst</th>
                                    <td class="pb-3">
                                        <span>{{ acceptedStartMoment.firstEventDate }}</span>
                                    </td>
                                </tr>
                                <tr v-if="acceptedStartMoment.lastEventDate">
                                    <th scope="row" class="text-left pb-3">Laatste bijeenkomst</th>
                                    <td class="pb-3">
                                        {{ acceptedStartMoment.lastEventDate }}
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr data-test="first-start-moment">
                                    <th
                                        scope="row"
                                        class="text-left py-3 d-flex align-items-center"
                                    >
                                        <span>Eerste keuze</span>
                                        <recess-tooltip v-if="showNoFinalStartMomentIcon">
                                            <recess-icon icon="danger" color="warning" />
                                            <template #content>
                                                <span class="d-block u-max-width-500"
                                                    >Let op: De uitvoerder van de
                                                    ontwikkelactiviteit heeft (nog) niet aangegeven
                                                    wat uw definitieve startmoment wordt. Het
                                                    startmoment kan mogelijk afwijken van uw 1e
                                                    keuze.</span
                                                >
                                            </template>
                                        </recess-tooltip>
                                    </th>
                                    <td class="w-50 py-3">
                                        <template v-if="firstChoice">
                                            <div>
                                                {{ firstChoice.displayDate }}
                                                <span v-if="firstChoice.displayDay"
                                                    >({{ firstChoice.displayDay }})</span
                                                >
                                            </div>
                                            <div>{{ firstChoice.location || '-' }}</div>
                                        </template>
                                        <span v-else>n.v.t.</span>
                                    </td>
                                </tr>
                                <tr v-if="firstChoice && firstChoice.firstEventDate">
                                    <th scope="row" class="text-left pb-3">Eerste bijeenkomst</th>
                                    <td class="pb-3">
                                        <span>{{ firstChoice.firstEventDate }}</span>
                                    </td>
                                </tr>
                                <tr v-if="firstChoice && firstChoice.lastEventDate">
                                    <th scope="row" class="text-left pb-3">Laatste bijeenkomst</th>
                                    <td class="pb-3">
                                        {{ firstChoice.lastEventDate }}
                                    </td>
                                </tr>
                                <tr data-test="second-start-moment">
                                    <th
                                        scope="row"
                                        class="text-left pb-3 d-flex align-items-center"
                                    >
                                        <span>Tweede keuze</span>
                                    </th>
                                    <td class="pb-3">
                                        <template v-if="secondChoice">
                                            <div>
                                                {{ secondChoice.displayDate }}
                                                <span v-if="secondChoice.displayDay"
                                                    >({{ secondChoice.displayDay }})</span
                                                >
                                            </div>
                                            <div>{{ secondChoice.location || '-' }}</div>
                                        </template>
                                        <span v-else>n.v.t.</span>
                                    </td>
                                </tr>
                            </template>
                        </template>

                        <template v-if="enrollment.courseDetails">
                            <tr data-test="course-details-provider">
                                <th scope="row" class="text-left pb-3">Opleider</th>
                                <td class="pb-3">{{ enrollment.courseDetails.providerName }}</td>
                            </tr>
                            <tr data-test="course-details-duration">
                                <th scope="row" class="text-left pb-3">
                                    Duur van de ontwikkelactiviteit
                                </th>
                                <td class="pb-3">
                                    {{ enrollment.courseDetails.durationValue }}
                                    {{ enrollment.courseDetails.durationUnit }}
                                </td>
                            </tr>
                            <tr data-test="course-details-study-load">
                                <th scope="row" class="text-left pb-3">Duur van de studielast</th>
                                <td class="pb-3">
                                    {{ courseStudyLoadDisplayValue }}
                                </td>
                            </tr>
                            <tr data-test="course-details-learning-method">
                                <th scope="row" class="text-left pb-3">Leermethode</th>
                                <td class="pb-3">
                                    {{ enrollment.courseDetails.learningMethodFormat }}
                                    <span v-if="enrollment.courseDetails.learningMethodType">
                                        ({{ enrollment.courseDetails.learningMethodType }})</span
                                    >
                                </td>
                            </tr>
                        </template>

                        <tr v-if="firstApproval">
                            <th scope="row" class="text-left py-3">Eerste goedkeuring</th>
                            <td class="py-3">
                                <div v-if="firstApproval.dateOfApproval">
                                    {{ firstApproval.dateOfApproval }}
                                </div>
                                <div v-if="firstApproval.fullName">
                                    {{ firstApproval.fullName }}
                                </div>
                            </td>
                        </tr>

                        <tr v-if="secondApproval">
                            <th scope="row" class="text-left pb-3">Tweede goedkeuring</th>
                            <td class="pb-3">
                                <div v-if="secondApproval.dateOfApproval">
                                    {{ secondApproval.dateOfApproval }}
                                </div>
                                <div v-if="secondApproval.fullName">
                                    {{ secondApproval.fullName }}
                                </div>
                            </td>
                        </tr>

                        <tr v-if="rejector">
                            <th scope="row" class="text-left pb-3">Afgekeurd door</th>
                            <td class="pb-3">
                                <div v-if="rejector.fullName">{{ rejector.fullName }}</div>
                            </td>
                        </tr>

                        <tr>
                            <th scope="row" class="text-left">Laatste statuswijziging</th>
                            <td>
                                <template v-if="currentStatus">
                                    <div v-if="currentStatus.name" data-test="status-name">
                                        {{ currentStatus.name }}
                                    </div>
                                    <div v-if="currentStatus.displayDate">
                                        {{ currentStatus.displayDate }}
                                    </div>
                                    <div v-if="currentStatus.changedByFullName">
                                        {{ currentStatus.changedByFullName }}
                                    </div>
                                    <div v-if="currentStatus.rejectReasonDefaultMessage">
                                        {{ currentStatus.rejectReasonDefaultMessage }}.
                                    </div>
                                    <div v-if="currentStatus.rejectReasonCustomMessage">
                                        {{ currentStatus.rejectReasonCustomMessage }}
                                    </div>
                                </template>
                                <span v-else>-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <template v-if="showUpdateStartMoments">
                    <recess-divider />
                    <start-moment-selection
                        ref="startMomentSelection"
                        :course="course"
                        :start-moments="startMoments"
                        :selected-start-moments="selectedStartMoments"
                        :facets="facets"
                        :startmoment-updates-enabled="showUpdateStartMoments"
                        :assortment-id="assortmentId"
                        :total-count="totalCount"
                        :page-number="pageNumber"
                        :page-size="top"
                        @pagination-clicked="
                            (pageNumber) => goToPage({ pageNumber, shouldFetchOrders })
                        "
                        @select-start-moment="(startMoment) => startMomentsSelected(startMoment)"
                        @clear-start-moment="updateSelectedStartMoments"
                        @clear-start-moments="updateSelectedStartMoments"
                        @facet-change="
                            (selectedFacets) =>
                                setFacetsAndSearchStartMoments({
                                    selectedFacets,
                                    shouldFetchOrders
                                })
                        "
                    />
                </template>
            </section>

            <recess-divider />

            <section>
                <h4 class="u-textcolor-primary">Persoonlijke gegevens</h4>
                <table aria-label="Personal detail" class="w-100">
                    <tbody>
                        <tr>
                            <th scope="row" class="text-left">Voornaam</th>
                            <td class="w-50" data-test="firstName">
                                {{ enrollment.firstName }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-left">Tussenvoegsel(s)</th>
                            <td data-test="middleName">{{ enrollment.middleName || '-' }}</td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-left">Achternaam</th>
                            <td data-test="lastName">{{ enrollment.lastName }}</td>
                        </tr>
                        <template v-if="$isAllowed('displayEnrollmentPersonalData', enrollment)">
                            <tr v-if="$isAllowed('displayBirthDateField')">
                                <th scope="row" class="text-left">Geboortedatum</th>
                                <td data-test="birthDay">{{ formatDate(enrollment.birthDay) }}</td>
                            </tr>
                            <tr v-if="$isAllowed('displayBirthPlaceField')">
                                <th scope="row" class="text-left">Geboorteplaats</th>
                                <td data-test="birthPlace">{{ enrollment.birthPlace }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </section>

            <recess-divider />

            <section>
                <h4 class="u-textcolor-primary">Contactgegevens</h4>

                <table aria-label="Contact detail" class="w-100">
                    <tbody>
                        <tr>
                            <th scope="row" class="text-left">E-mailadres</th>
                            <td class="w-50" data-test="email">
                                {{ enrollment.email }}
                            </td>
                        </tr>
                        <template v-if="$isAllowed('displayEnrollmentPersonalData', enrollment)">
                            <tr>
                                <th scope="row" class="text-left">Telefoonnummer</th>
                                <td data-test="phoneNumber">{{ enrollment.phoneNumber }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-left">Postcode</th>
                                <td data-test="postCode">{{ enrollment.addressPostalCode }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-left">Huisnummer</th>
                                <td data-test="houseNumber">
                                    {{ enrollment.addressHouseNumber }}
                                    {{ enrollment.addressHouseNumberAdditive }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-left">Straat</th>
                                <td data-test="street">{{ enrollment.addressStreet }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-left">Plaats</th>
                                <td data-test="city">{{ enrollment.addressCity }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-left">Land</th>
                                <td data-test="country">{{ countryDisplayValue }}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </section>

            <recess-divider />

            <section v-if="$isAllowed('displayEnrollReason') && enrollReasonDisplayValue">
                <enrollment-reason-summary
                    :enroll-reason-display-value="enrollReasonDisplayValue"
                    :enroll-reason-message="enrollment.enrollReasonMessage"
                />
                <recess-divider />
            </section>

            <section>
                <h4 class="u-textcolor-primary">Autorisatie</h4>
                <table aria-label="Authorization detail" class="w-100">
                    <tbody>
                        <tr v-if="$isAllowed('displayCostCenter')">
                            <th scope="row" class="text-left">Kostenplaats</th>
                            <td class="w-50">{{ enrollment.costCenter || '-' }}</td>
                        </tr>
                        <tr v-if="$isAllowed('displayPONumber')">
                            <th scope="row" class="text-left">PO nummer</th>
                            <td class="w-50">{{ enrollment.purchaseOrderNumber || '-' }}</td>
                        </tr>
                        <tr>
                            <th scope="row" class="text-left">Project code</th>
                            <td class="w-50">OPLZ</td>
                        </tr>
                        <tr v-if="enrollment.studyContractTemplate">
                            <th scope="row" class="text-left">Studieovereenkomst</th>
                            <td class="w-50">{{ enrollment.studyContractTemplate.name }}</td>
                        </tr>
                        <tr v-if="assignedApproverName">
                            <th scope="row" class="text-left">Toegewezen beoordelaar</th>
                            <td class="w-50">
                                {{ assignedApproverName }}
                                <span v-if="assignedApproverEmail"
                                    >({{ assignedApproverEmail }})</span
                                >
                            </td>
                        </tr>
                        <tr
                            v-if="
                                $isAllowed('displayKeyAccounts') &&
                                enrollment.keyAccount &&
                                enrollment.keyAccount.name
                            "
                        >
                            <th scope="row" class="text-left">Key-Account</th>
                            <td class="w-50">{{ enrollment.keyAccount.name }}</td>
                        </tr>
                        <tr v-if="isSubsidyEnabled && enrollment.isSubsidized">
                            <th scope="row" class="text-left">Subsidie</th>
                            <td class="w-50">Ja</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section v-if="showLearningResultSharing" data-test="learning-result-section">
                <recess-divider />

                <h4 class="u-textcolor-primary">Instemming met delen van resultaten</h4>

                <div
                    v-if="showNotApplicable"
                    data-test="learning-result-sharing-consents-not-applicable"
                >
                    <p>Niet van toepassing</p>
                </div>
                <div v-else>
                    <table aria-label="Learning results" class="w-100">
                        <tbody>
                            <tr>
                                <th scope="row" class="text-left pb-3">
                                    Toestemming om leerresultaten te delen
                                </th>
                                <td class="w-50">
                                    <div>{{ hasAllowedSharingLearningResultString }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row d-flex align-items-center mb-3">
                        <strong class="col-6">Toestemming gegeven</strong>
                        <recess-button
                            v-if="showChangeLearningResultConsents"
                            data-test="change-learning-result-sharing-consents-button"
                            title="Wijzig"
                            variant="tertiary"
                            @click.native="showEditLearningResultSharingConsentsModal = true"
                        />
                    </div>

                    <learning-result-sharing-matrix
                        v-if="showLearningResultSharingConsentsGiven"
                        data-test="learning-result-sharing-consents-given"
                        :learning-result-categories-to-show="learningResultCategoriesToShow"
                        :selected-learning-result-options="studentSelectedLearningResultOptions"
                    />

                    <section
                        v-if="showRequestedAndAcceptedLearningResultSharingConsents"
                        data-test="learning-result-sharing-consents-required-accepted"
                    >
                        <recess-divider />

                        <strong>Toestemming gevraagd</strong>
                        <learning-result-sharing-matrix
                            :learning-result-categories-to-show="learningResultCategoriesToShow"
                            :selected-learning-result-options="
                                approverSelectedLearningResultOptions
                            "
                        />

                        <recess-divider />

                        <strong>Toestemming gedeeld met opleider</strong>
                        <learning-result-sharing-matrix
                            :learning-result-categories-to-show="learningResultCategoriesToShow"
                            :selected-learning-result-options="matchingLearningResultOptions"
                        />
                    </section>
                </div>

                <edit-learning-result-sharing-consents-modal
                    data-test="edit-learning-result-sharing-consents-modal"
                    :enrollment="enrollment"
                    :show-edit-learning-result-sharing-consents-modal="
                        showEditLearningResultSharingConsentsModal
                    "
                    @is-loading="(val) => (isUpdatingSharingConsent = val)"
                    @close="showEditLearningResultSharingConsentsModal = false"
                    @reload="fetchEnrollment"
                />
            </section>
        </div>

        <recess-alert
            v-if="showErrorMessage"
            text="Uw inschrijving is niet bekend. Probeer een andere inschrijving."
        />

        <recess-divider />

        <section>
            <recess-button
                :url="goBackButton"
                class="col-12 col-md-auto mb-3 mb-md-0"
                title="Terug"
                variant="tertiary"
            />
            <recess-button
                v-if="enrollment && $isAllowed('cancelEnrollment', enrollment)"
                variant="primary"
                class="u-position-right col-12 col-md-auto mb-3 ml-4"
                title="Aanvraag annuleren"
                data-test="cancel-enrollment-btn"
                @click="showCancelEnrollmentModal = true"
            />
            <recess-button
                v-if="showUpdateStartMoments"
                variant="primary"
                :disabled="!selectedStartMoments.length"
                class="u-position-right col-12 col-md-auto mb-3"
                data-test="verify-button"
                title="Opslaan"
                @click="updateStartmoments()"
            />
        </section>

        <recess-divider />

        <div v-if="showStartmomentsMessage && !isLoading">
            <recess-alert
                :type="isStartmomentsError ? 'error' : 'success'"
                :text="startmomentsMessage"
            />

            <recess-divider />
        </div>
    </recess-layout-static>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import enrollmentClient from '../../../api/enrollmentClient'
import DateUtils from '../../../utils/DateUtils'
import startMomentHelper from '../../../modules/startMomentHelper'
import routePerimeter from '../../../perimeters/routePerimeter'
import enrollmentPerimeter from '../../../perimeters/enrollmentPerimeter'
import * as enrollmentStatus from '../../../constants/enrollmentStatus'
import CancelEnrollmentPopupComponent from '../../molecules/CancelEnrollmentPopupComponent/CancelEnrollmentPopupComponent.vue'
import EnrollmentPriceComponent from '../../organisms/Enrollments/EnrollmentPriceComponent/EnrollmentPriceComponent.vue'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import StartMomentSelection from '../../organisms/StartMomentSelection/StartMomentSelection.vue'
import * as productTypes from '../../../constants/productTypes'
import { edumsInvalidOrderStatuses, oplzInvalidStatuses } from '../../../constants/invalidStatuses'
import LearningResultSharingMatrix from '../../organisms/LearningResultSharing/LearningResultSharingMatrix.vue'
import learningResultSharingTypes from '../../../constants/learningResultSharingTypes'
import LearningResultSharingUtils from '../../../utils/LearningResultSharingUtils'
import EditLearningResultSharingConsentsModal from '../../organisms/LearningResultSharing/EditLearningResultSharingConsentsModal.vue'
import * as roleIds from '../../../constants/roleIds'
import EnrollmentDetailStartMomentsMixin from '../../../mixins/enrollment-detail-start-moments-mixin'
import learningResultSharingPerimeter from '../../../perimeters/learningResultSharingPerimeter'
import EnrollmentReasonSummary from '../../atoms/EnrollmentReasonSummaryComponent/EnrollmentReasonSummary.vue'

export default {
    name: 'EnrollmentDetailsPage',
    components: {
        LoaderComponent,
        CancelEnrollmentPopupComponent,
        EnrollmentPriceComponent,
        StartMomentSelection,
        LearningResultSharingMatrix,
        EditLearningResultSharingConsentsModal,
        EnrollmentReasonSummary
    },
    mixins: [EnrollmentDetailStartMomentsMixin],
    perimeters: [
        routePerimeter,
        enrollmentPerimeter,
        featuresPerimeter,
        learningResultSharingPerimeter
    ],
    beforeRouteEnter(_, from, next) {
        next((vm) => {
            vm.prevRoute = from
        })
    },
    data() {
        return {
            onBehalfOfText: 'uit naam van',
            isFetchingEnrollment: false,
            isSubmitting: false,
            enrollment: null,
            showErrorMessage: false,
            showCancelEnrollmentModal: false,
            showStartmomentsMessage: false,
            isStartmomentsError: false,
            startmomentsMessage: '',
            selectedStartMoments: [],
            acceptedStartMoment: null,
            showEditLearningResultSharingConsentsModal: false,
            isUpdatingSharingConsent: false,
            prevRoute: null
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['countries', 'enrollReasons', 'course']),
        ...mapState('userModule', ['user']),
        ...mapGetters('enrollmentModule', ['courseProductType', 'courseHasStartMoments']),
        ...mapGetters('accountModule', [
            'isSubsidyEnabled',
            'limitIncompanyEnrollments',
            'isLearningResultEnabled',
            'firstStartMomentDate',
            'finalStartMomentDate'
        ]),
        showLearningResultSharing() {
            if (this.isFetchingEnrollment || !this.isLearningResultEnabled) return false
            // Learning result sharing consents to be shown to employer roles only after approval/student verification
            return (
                !(
                    this.$isAllowed('consideredAsTeamLeader') &&
                    (this.isEnrollmentPendingApproval ||
                        (this.isTeamLeaderEnrollment &&
                            this.isEnrollmentPendingStudentVerification))
                ) && !this.isCoordinatorEnrollment
            )
        },
        showNotApplicable() {
            // Show Not applicable message when no consents have been asked by approver ("niet-verplicht")
            return (
                ((this.isTeamLeaderEnrollment && !this.isEnrollmentPendingStudentVerification) ||
                    !this.isEnrollmentPendingApproval) &&
                !this.enrollment?.enrollmentTeamLeaderLearningResult?.learningResultSharingType
            )
        },
        showLearningResultSharingConsentsGiven() {
            // Show given consents matrix when student consents are available
            return LearningResultSharingUtils.hasStudentAgreedForLearningResultSharing(
                this.enrollment?.enrollmentLearningResult
            )
        },
        showRequestedAndAcceptedLearningResultSharingConsents() {
            // Show these two blocks only if not pending on student verification or approval
            return (
                (this.isTeamLeaderEnrollment && !this.isEnrollmentPendingStudentVerification) ||
                !this.isEnrollmentPendingApproval
            )
        },
        isEnrollmentPendingApproval() {
            return this.enrollment?.statusId === enrollmentStatus.PENDINGAPPROVAL
        },
        isEnrollmentPendingStudentVerification() {
            return this.enrollment?.statusId === enrollmentStatus.PENDINGSTUDENTVERIFICATION
        },
        isCoordinatorEnrollment() {
            return this.enrollment?.roleId === roleIds.COORDINATOR
        },
        isTeamLeaderEnrollment() {
            return this.enrollment?.roleId === roleIds.TEAMLEADER
        },
        showChangeLearningResultConsents() {
            return (
                this.$isAllowed('editLearningResultSharingConsents') &&
                (this.enrollment?.statusId === enrollmentStatus.PENDINGAPPROVAL ||
                    this.enrollment?.enrollmentTeamLeaderLearningResult
                        ?.learningResultSharingType) &&
                this.isValidStatus
            )
        },
        learningResultSharingType() {
            return LearningResultSharingUtils.mapLearningResultSharingType(
                this.enrollment?.enrollmentTeamLeaderLearningResult?.learningResultSharingType
            )
        },
        learningResultCategoriesToShow() {
            if (this.enrollment?.statusId === enrollmentStatus.PENDINGAPPROVAL)
                return Object.keys(learningResultSharingTypes)
                    ?.filter((key) => key !== learningResultSharingTypes.NONE.key)
                    .map((key) => learningResultSharingTypes[key])

            return Object.keys(learningResultSharingTypes)?.reduce((acc, key) => {
                if (key === this.learningResultSharingType)
                    acc.push(learningResultSharingTypes[key])

                return acc
            }, [])
        },
        studentSelectedLearningResultOptions() {
            if (this.learningResultSharingType)
                return LearningResultSharingUtils.getStudentSelectedLearningResultSharingByType(
                    this.learningResultSharingType,
                    this.enrollment?.enrollmentLearningResult
                )
            return LearningResultSharingUtils.getStudentSelectedLearningResultSharingConsents(
                this.enrollment?.enrollmentLearningResult
            )
        },
        approverSelectedLearningResultOptions() {
            return LearningResultSharingUtils.getApproverSelectedLearningResultSharing(
                this.learningResultSharingType,
                this.enrollment?.enrollmentTeamLeaderLearningResult
            )
        },
        matchingLearningResultOptions() {
            return LearningResultSharingUtils.getMatchedLearningResultSharing(
                this.learningResultSharingType,
                this.enrollment?.enrollmentMutualLearningResult
            )
        },
        enrollmentLearningResult() {
            return this.enrollment?.enrollmentLearningResult
        },
        hasAllowedSharingLearningResultString() {
            return LearningResultSharingUtils.hasStudentAgreedForLearningResultSharing(
                this.enrollment?.enrollmentLearningResult
            )
                ? 'Ja'
                : 'Nee'
        },
        courseId() {
            return this.enrollment?.eduMSCourseId
        },
        enrollReasonDisplayValue() {
            return this.enrollReasons?.find((x) => x.value === this.enrollment.enrollReasonCode)
                ?.displayText
        },
        showNoFinalStartMomentIcon() {
            return !!this.firstChoice && this.isValidStatus
        },
        showUpdateStartMoments() {
            return (
                this.$isAllowed('updateStartmoments', this.enrollment) &&
                !!this.enrollment?.eduMSFirstStartMomentId &&
                (this.startMoments?.length ||
                    ((this.finalStartMomentDate || this.firstStartMomentDate) && this.selectedStartMoments?.length)) && 
                !this.isFetchingEnrollment
            )
        },
        isValidStatus() {
            return (
                !oplzInvalidStatuses.includes(this.enrollment?.statusId) &&
                !edumsInvalidOrderStatuses.includes(this.enrollment?.eduMSOrderStatus)
            )
        },
        isLoading() {
            return (
                this.isFetchingEnrollment ||
                this.isSubmitting ||
                this.isFetchingStartmoments ||
                this.isUpdatingSharingConsent
            )
        },
        goBackButton() {
            // Redirect to previous route if applicable
            if (this.prevRoute?.name) {
                return {
                    name: this.prevRoute.name,
                    params: this.prevRoute.params,
                    query: this.prevRoute.query
                }
            }

            // If query string contains delegation action, go back there
            if (this.$route.query.delegatedAction) {
                return { name: 'my-actions', query: this.$route.query }
            }

            // If is my own enrollment redirect to my development page
            if (this.user && this.enrollment && this.user.userId === this.enrollment.userId) {
                return { name: 'my-development', query: this.$route.query }
            }

            // If not redirect to my team page if allow otherwise to my development page
            return this.$isAllowed('routeMyTeamPage')
                ? { name: 'my-team', query: this.$route.query }
                : { name: 'my-development', query: this.$route.query }
        },
        countryDisplayValue() {
            const country = this.countries?.find(
                (m) => m.value?.toLowerCase() === this.enrollment?.addressCountryCode?.toLowerCase()
            )
            return country ? country.displayText : '-'
        },
        isFirstPendingApproval() {
            if (!this.currentStatus) return false

            return this.currentStatus.statusId === enrollmentStatus.PENDINGAPPROVAL
        },
        isSecondPendingApproval() {
            if (!this.currentStatus) return false

            return this.currentStatus.statusId === enrollmentStatus.PENDINGSECONDAPPROVAL
        },
        assignedApproverName() {
            if (this.isFirstPendingApproval) return this.enrollment?.assignedApprover?.fullName
            if (this.isSecondPendingApproval)
                return this.enrollment?.assignedSecondApprover?.fullName

            return null
        },
        assignedApproverEmail() {
            if (this.isFirstPendingApproval) return this.enrollment?.assignedApprover?.emailAddress
            if (this.isSecondPendingApproval)
                return this.enrollment?.assignedSecondApprover?.emailAddress

            return null
        },
        startMomentsToRenders() {
            if (this.courseHasStartMoments && this.startMoments?.length) {
                let startmomentsNotExpensiveThanEnrollmentPrice = []

                // If the enrollment has already been through all approvals, then show only startmoments with price lower than or equal to the enrollment price so that no further approvals are necessary
                if (this.$isAllowed('updateStartmoments', this.enrollment)) {
                    startmomentsNotExpensiveThanEnrollmentPrice =
                        this.startMoments?.filter(
                            (startmoment) =>
                                startmoment.priceInfo?.totalPrice <=
                                this.enrollment?.displayPrice?.totalPrice
                        ) ?? this.startMoments
                }

                return startmomentsNotExpensiveThanEnrollmentPrice
            }
            return null
        },
        firstChoice() {
            if (!this.enrollment.eduMSFirstStartMomentId) return null

            return {
                displayDate: this.enrollment.courseDetails?.firstStartMomentDisplayDate || '-',
                displayDay: this.enrollment.courseDetails?.firstStartMomentDisplayDayOfWeek,
                location: this.enrollment.courseDetails?.firstStartMomentCity,
                firstEventDate: this.formatDateTime(this.enrollment.courseDetails?.firstEventDate),
                lastEventDate: this.formatDateTime(this.enrollment.courseDetails?.lastEventDate)
            }
        },
        secondChoice() {
            if (!this.enrollment.eduMSSecondStartMomentId) return null

            return {
                displayDate: this.enrollment.courseDetails?.secondStartMomentDisplayDate || '-',
                displayDay: this.enrollment.courseDetails?.secondStartMomentDisplayDayOfWeek,
                location: this.enrollment.courseDetails?.secondStartMomentCity
            }
        },
        currentStatus() {
            if (!this.enrollment) return null

            const currentStatus = this.enrollment?.currentStatus
            if (!currentStatus) return null

            return {
                statusId: currentStatus.enrollmentStatusId,
                name: currentStatus.displayValue,
                displayDate: this.formatDate(currentStatus.dateTimeChanged),
                changedByFullName: currentStatus.changedByFullName,
                rejectReasonDefaultMessage: currentStatus.enrollmentStatusChangeReason,
                rejectReasonCustomMessage: currentStatus.userMessage
            }
        },
        firstApproval() {
            if (!this.enrollment?.approver?.fullName || !this.enrollment?.dateTimeApproval)
                return null
            // check if there was a delegator for first approval, if so add it to the fullName displayed
            const approverFullName = this.enrollment?.approver?.fullName
            return {
                fullName: this.enrollment?.approvalDelegator
                    ? `${approverFullName} ${this.onBehalfOfText} ${this.enrollment.approvalDelegator.fullName}`
                    : approverFullName,
                dateOfApproval: this.formatDate(this.enrollment?.dateTimeApproval)
            }
        },
        secondApproval() {
            if (
                !this.enrollment?.secondApprover?.fullName ||
                !this.enrollment?.dateTimeSecondApproval
            )
                return null
            // check if there was a delegator for second approval, if so add it to the fullName displayed
            const secondApproverFullName = this.enrollment?.secondApprover?.fullName
            return {
                fullName: this.enrollment?.secondApprovalDelegator
                    ? `${secondApproverFullName} ${this.onBehalfOfText} ${this.enrollment.secondApprovalDelegator.fullName}`
                    : secondApproverFullName,
                dateOfApproval: this.formatDate(this.enrollment?.dateTimeSecondApproval)
            }
        },
        rejector() {
            if (!this.enrollment?.rejector?.fullName) return null
            let rejectorFullName = this.enrollment?.rejector?.fullName
            // check the rejection type (first or second approval rejection) and if there is a delegation action complete of said type
            if (this.enrollment.rejectionType === 1 && this.enrollment?.approvalDelegator) {
                rejectorFullName = `${rejectorFullName} ${this.onBehalfOfText} ${this.enrollment.approvalDelegator.fullName}`
            } else if (
                this.enrollment.rejectionType === 2 &&
                this.enrollment?.secondApprovalDelegator
            ) {
                rejectorFullName = `${rejectorFullName} ${this.onBehalfOfText} ${this.enrollment.secondApprovalDelegator.fullName}`
            }
            return {
                fullName: rejectorFullName
            }
        },
        shouldFetchOrders() {
            return (
                this.courseHasStartMoments &&
                this.courseProductType === productTypes.INCOMPANY &&
                this.limitIncompanyEnrollments
            )
        },
        projectRequestNameId() {
            return `${this.enrollment?.projectRequestName} (${this.enrollment?.projectRequestId})`
        },
        courseStudyLoadDisplayValue() {
            if (!this.course?.learningMethod?.studyLoad?.value) return '-'

            return `${this.course.learningMethod.studyLoad.value} ${this.course.learningMethod.studyLoad.unitDisplayValue?.toLowerCase()}`
        }
    },
    beforeDestroy() {
        this.resetState()
    },
    mounted() {
        this.initialize()
    },
    methods: {
        ...mapActions('startMomentsModule', ['initializeStartMomentsAndOrders']),
        ...mapActions('enrollmentModule', ['getCountries', 'getEnrollReasons', 'getCourse']),
        ...mapMutations('enrollmentModule', ['resetState']),
        async initialize() {
            const promises = [this.getCountries(), this.fetchEnrollment()]
            if (this.$isAllowed('displayEnrollReason')) promises.push(this.getEnrollReasons())
            await Promise.all(promises)

            if (this.$isAllowed('updateStartmoments', this.enrollment)) {
                await this.initializeStartMomentsAndOrders({
                    courseId: this.courseId,
                    assortmentId: this.enrollment?.assortmentId,
                    shouldFetchOrders: this.shouldFetchOrders
                })
            }

            await this.setSelectedStartMoments()
            await this.setAcceptedStartMoment()
        },
        async setSelectedStartMoments() {
            if (
                this.enrollment?.eduMSFirstStartMomentId ||
                this.enrollment?.eduMSSecondStartMomentId
            ) {
                const selectedStartMoments = await this.getChosenStartMoments(
                    this.enrollment.eduMSFirstStartMomentId,
                    this.enrollment.eduMSSecondStartMomentId
                )

                selectedStartMoments?.forEach((sm) => {
                    const startMomentToAdd = startMomentHelper.mapSelectedStartMoment(
                        sm,
                        this.selectedStartMoments
                    )
                    this.selectedStartMoments.push(startMomentToAdd)
                })
            }
        },
        async setAcceptedStartMoment() {
            if (!this.enrollment?.eduMSAcceptedStartMomentId) return

            this.acceptedStartMoment = {
                displayDate: this.enrollment.courseDetails?.acceptedStartMomentDisplayDate || '-',
                displayDay: this.enrollment.courseDetails?.acceptedStartMomentDisplayDayOfWeek,
                location: this.enrollment.courseDetails?.acceptedStartMomentCity,
                firstEventDate: this.formatDateTime(this.enrollment.courseDetails?.firstEventDate),
                lastEventDate: this.formatDateTime(this.enrollment.courseDetails?.lastEventDate)
            }
        },
        async fetchEnrollment() {
            try {
                this.isFetchingEnrollment = true
                const enrollmentId = this.$route.query?.enrollment || null

                this.enrollment = await enrollmentClient.getEnrollment(enrollmentId)
                await this.getCourse(this.courseId)
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isFetchingEnrollment = false
            }
        },
        async updateStartmoments() {
            try {
                this.showStartmomentsMessage = false

                if (!this.selectedStartMoments?.length) {
                    this.startmomentsMessage = 'Kies ten minste één startmoment.'
                    this.isStartmomentsError = true
                    this.showStartmomentsMessage = true
                    return
                }

                const postData = {
                    firstStartMomentId: this.selectedStartMoments[0].startMomentId
                }

                if (this.selectedStartMoments[1]) {
                    postData.secondStartMomentId = this.selectedStartMoments[1].startMomentId
                }

                this.isSubmitting = true

                await enrollmentClient.updateStartmoments(this.enrollment.enrollmentId, postData)
                this.enrollment = await enrollmentClient.getEnrollment(this.enrollment.enrollmentId)
                this.$refs.startMomentSelection.showStartMoments = false
                this.startmomentsMessage = 'De startmomenten zijn aangepast.'
                this.isStartmomentsError = false
            } catch (error) {
                this.startmomentsMessage =
                    'Het wijzigen van startmomenten is momenteel niet mogelijk.'
                this.isStartmomentsError = true
            } finally {
                this.showStartmomentsMessage = true
                this.isSubmitting = false
            }
        },
        startMomentsSelected(startMoment) {
            const startMomentToAdd = startMomentHelper.mapSelectedStartMoment(
                startMoment,
                this.selectedStartMoments
            )

            if (startMomentToAdd) this.selectedStartMoments.push(startMomentToAdd)
        },
        updateSelectedStartMoments(index) {
            if (index) this.selectedStartMoments?.splice(index, 1)
            else this.selectedStartMoments = []
        },
        formatDate: (d) => DateUtils.formatDate(d),
        formatDateTime: (d) => DateUtils.formatDateTime(d)
    }
}
</script>
