import DataQuery from './dataQuery'

export default class DataQueryBuilder {
    constructor() {
        this.DataQuery = new DataQuery()
    }

    setSort(field, direction) {
        this.DataQuery.setSort(field, direction)
        return this
    }

    setPagination(skip, take) {
        this.DataQuery.setPagination(skip, take)
        return this
    }

    addExpands(expands) {
        this.DataQuery.expands.push(expands)
        return this
    }

    addFilter(dataQueryFilter) {
        this.DataQuery.filters.push(dataQueryFilter)
        return this
    }

    addFilters(dataQueryFilters) {
        dataQueryFilters
            .forEach((filter) => {
                if(filter.value != null) {
                    this.DataQuery.filters.push(filter)
                }
            })
        return this
    }

    build() {
        return this.DataQuery
    }
}
