import courseClient from '../../api/courseClient'
import tabKeys from '../../components/organisms/CoursePage/tabKeys'
import courseAccessStatuses from '../../constants/courseAccessStatuses'
import * as courseLearningMethodTypes from '../../constants/courseLearningMethodTypes'
import * as productTypes from '../../constants/productTypes'
import courseAccessHelper from '../../modules/courseAccessHelper'
import * as courseHeadings from '../../modules/courseHeadings'
import startMomentHelper from '../../modules/startMomentHelper'
import { mapCourseAccessErrorMessage } from '../../utils/MapUtils'

export default {
    namespaced: true,
    state() {
        return {
            course: null,
            price: null,
            isFetchingCoursePrice: false,
            courseAssortmentId: null,
            checkingIfUserHasAccess: false,
            userHasAccess: true,
            cannotEnrollMessage: null
        }
    },
    getters: {
        cannotEnrollMessage(state) {
            return state.cannotEnrollMessage
        },
        courseId(state) {
            return state.course?.id
        },
        courseAssortmentId(state) {
            return state.courseAssortmentId
        },
        userHasAccess(state) {
            return state.userHasAccess
        },
        programContent(state) {
            return courseHeadings.getContentForHeading(state.course?.content, tabKeys.PROGRAM)
        },
        generalContent(state) {
            return courseHeadings.getContentForHeading(state.course?.content, tabKeys.GENERAL)
        },
        certificationContent(state) {
            return courseHeadings.getContentForHeading(state.course?.content, tabKeys.CERTIFICATION)
        },
        planningContent(state) {
            return courseHeadings.getContentForHeading(state.course?.content, tabKeys.PLANNING)
        },
        investmentContent(state) {
            return courseHeadings.getContentForHeading(state.course?.content, tabKeys.INVESTMENT)
        },
        totalTabContent(state) {
            return courseHeadings.totalTabContent(state.course?.content)
        },
        restContent: state => {
            return courseHeadings.restContent(state.course?.content)
        },
        introContent: state => {
            return courseHeadings.introContent(state.course?.content)
        },
        descriptionContent: state => {
            return courseHeadings.descriptionContent(state.course?.content)
        },
        showGeneralContent(state, getters) {
            return !!(
                state.course &&
        (getters.generalContent ||
          state.course.segments ||
          getters.restContent ||
          getters.introContent)
            )
        },
        showProgramContent(state, getters) {
            return !!(state?.course && (getters.programContent || getters.descriptionContent))
        },
        showCertificationContent(state, getters) {
            return !!(
                state.course &&
        (getters.certificationContent ||
          state.course.diplomaType ||
          state.course.croho ||
          state.course.crebo)
            )
        },
        showInvestmentContent(_, getters) {
            return !!getters.investmentContent || !!getters.costOverview
        },
        showPlanningContent(state, _, __, rootGetters) {
            const isNoaProvider = rootGetters['oleProvidersModule/isNoaProvider']
            return !state.course?.externalId || !isNoaProvider(state.course.providerId)
        },
        showStartmoments(state) {
            return !!state.course?.startMoments?.length
        },
        durationText(state) {
            const durationValue = state.course?.learningMethod?.duration?.value
            const durationUnit = state.course?.learningMethod?.duration?.unit
            return durationValue && durationUnit ? `${durationValue} ${durationUnit}` : null
        },
        studyLoadText(state) {
            const studyLoadValue = state.course?.learningMethod?.studyLoad?.value
            const studyLoadUnit = state.course?.learningMethod?.studyLoad?.unitDisplayValue
            return studyLoadValue && studyLoadUnit ? `${studyLoadValue} ${studyLoadUnit}` : null 
        },
        isClassRoomLearningMethod(state) {
            return (
                state.course?.learningMethod?.type?.toLowerCase() !== courseLearningMethodTypes.SELFPLANNED
            )
        },
        defaultTab(_, getters) {
            if (getters.showGeneralContent) return tabKeys.GENERAL
            if (getters.showProgramContent) return tabKeys.PROGRAM
            if (getters.showCertificationContent) return tabKeys.CERTIFICATION
            if (getters.showInvestmentContent) return tabKeys.INVESTMENT
            return tabKeys.PLANNING
        },
        costOverview(state) {
            return state.price?.cost?.details?.[0]
        },
        showCapacity(_, getters, rootState) {
            const showCapacityIconForAllCoursesSetting = rootState?.accountModule?.accountSettings?.showCapacityIconForAllCourses

            return startMomentHelper.showCapacityDetail(
                showCapacityIconForAllCoursesSetting, 
                getters.productType
            )
        },
        assortmentName(state) {
            return state.price?.assortmentName
        },
        productType(state) {
            return state.course?.productType
        },
        providerId(state) {
            return state.course?.providerId
        },
        // Limit incompany enrollments means if this is an incompany course, limit/block the start moments have reached their max capacity
        limitIncompanyEnrollmentsAndIsIncompany(_, getters, rootgetters) {
            return rootgetters?.accountModule?.accountSettings?.limitIncompanyEnrollments && getters.productType === productTypes.INCOMPANY
        }
    },
    mutations: {
        RESET_STATE(state) {
            state.course = null
            state.userHasAccess = true
            state.cannotEnrollMessage = null
            state.price = null
            state.assortmentId = null
            state.isFetchingCoursePrice = false
            state.checkingIfUserHasAccess = false
        },
        SET_COURSE(state, course) {
            state.course = course
        },
        SET_HAS_ACCESS(state, userHasAccess) {
            state.userHasAccess = userHasAccess
        },
        SET_CANNOT_ENROLL_MESSAGE(state, cannotEnrollMessage) {
            state.cannotEnrollMessage = cannotEnrollMessage
        },
        SET_CHECKING_ACCESS(state, isChecking) {
            state.checkingIfUserHasAccess = isChecking
        },
        SET_PRICE(state, price) {
            state.price = price
        },
        SET_ASSORTMENT_ID(state, assortmentId) {
            state.courseAssortmentId = assortmentId
        },
        SET_IS_FETCHING_COURSE_PRICE(state, isFetchingCoursePrice) {
            state.isFetchingCoursePrice = isFetchingCoursePrice
        }
    },
    actions: {
        async checkUserAccess({ commit, state, rootGetters }) {
            commit('SET_CHECKING_ACCESS', true)

            const includeFirstStartMomentDate = !!rootGetters['accountModule/firstStartMomentDate']
            const includeFinalStartMomentDate = !!rootGetters['accountModule/finalStartMomentDate']

            const courseAccessStatus = await courseAccessHelper.doesUserHaveAccess(state.course?.id, state.courseAssortmentId, includeFirstStartMomentDate, includeFinalStartMomentDate)
            const hasUserAccess = courseAccessStatus === courseAccessStatuses.HAS_ACCESS
            if (!hasUserAccess) {
                commit('SET_IS_COURSE_OUTSIDE_ASSORTMENT', courseAccessStatus === courseAccessStatuses.OUTSIDE_ASSORTED_PRODUCTS)
                commit('SET_CANNOT_ENROLL_MESSAGE', mapCourseAccessErrorMessage(courseAccessStatus))
            }
            commit('SET_HAS_ACCESS', hasUserAccess)
            
            commit('SET_CHECKING_ACCESS', false)
        },
        async getCourse({ commit }, courseId) {
            try {
                const course = await courseClient.getCourse(courseId)

                commit('SET_COURSE', course)

                return course
            } catch (error) {
                throw new Error(`Failed to fetch courses with error: ${error}`)
            }
        },
        async getCoursePrice({ commit, state }, courseRequest) {
            try {
                const id = courseRequest?.courseId || state.course?.id
                if (!id) return

                commit('SET_IS_FETCHING_COURSE_PRICE', true)

                const coursePrice = await courseClient.getPrice(id, courseRequest?.useTeamLeaderAssortments, courseRequest?.assortmentId)

                commit('SET_PRICE', coursePrice)
            } catch (error) {
                throw new Error(`Failed to fetch course price with error: ${error}`)
            } finally {
                commit('SET_IS_FETCHING_COURSE_PRICE', false)
            }
        }
    }
}
