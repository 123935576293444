<template>
  <div  class="c-enrollment-step">
    <h4 class="u-textcolor-primary">Datum en locatie kiezen</h4>
    <p>Kies hieronder een startmoment, variant en locatie. Je kunt eventueel een tweede keuze opgeven.</p>

    <start-moment-selection
      :course="course"
      :is-loading="isLoadingStartMomentsAndOrders"
      :start-moments="startMoments"
      :selected-start-moments="selectedStartMoments"
      startmoment-updates-enabled
      :assortment-id="courseAssortment"
      :facets="facets"
      :show-capacity="showCapacity"
      :nr-of-people-to-enroll="selectedStudents && selectedStudents.length"
      :total-count="totalCount"
      :page-number="pageNumber"
      :page-size="top"
      :show-no-start-moments-message="showNoStartMomentsMessage"
      default-open
      @pagination-clicked="val => onPaginationClicked(val)"
      @select-start-moment="selectStartMoment"
      @clear-start-moment="clearSelectedStartMoment"
      @clear-start-moments="clearStartMoments"
      @facet-change="selectedFacets => setFacets(selectedFacets)"
    >
      <template #start-moments-text>

        <template v-if="showNextStepButton">

          <a
            href="#"
            class="d-none d-md-block u-position-right"
            data-test="next-step-button"
            @click.prevent="nextStep"
          >Ga verder zonder 2e keuze</a>

          <h4 class="u-textcolor-primary">Maak hieronder uw 2e keuze: (niet verplicht)</h4>
          <div>Je kunt ook een tweede voorkeur opgeven voor jouw startmoment, locatie, en/of variant van de ontwikkelactiviteit.</div>

          <recess-divider />

          <a
            href="#"
            class="d-md-none"
            data-test="next-step-button"
            @click.prevent="nextStep"
          >Ga verder zonder 2e keuze</a>

          <recess-divider class="d-md-none" />
        </template>
      </template>
    </start-moment-selection>

    <enrollment-step-buttons 
      :show-back-button="showBackButton"
      :show-next-button="selectedStartMomentIds && selectedStartMomentIds.length === maxAmountOfStartmomentSelections" 
      v-on="{ ...$listeners, 'next-step': nextStep }"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import courseClient from '../../../../api/courseClient'
import * as enrollmentTypes from '../../../../constants/enrollmentTypes'
import StartMomentSelection from '../../StartMomentSelection/StartMomentSelection.vue'
import EnrollmentStepButtons from './EnrollmentStepButtons.vue'
import EnrollmentSearchStartMomentsMixin from '../../../../mixins/enrollment-search-start-moments-mixin'
import SearchQueryBuilder from '../../../../api/OData/searchQueryBuilder'
import ODataQueryFilter from '../../../../api/OData/odataQueryFilter'
import ODataFilterOperations from '../../../../api/OData/odataFilterOperations'
import startMomentHelper from '../../../../modules/startMomentHelper'
import DateUtils from '../../../../utils/DateUtils'

export default {
    name: 'EnrollmentStepStartmoments',
    components: {
        StartMomentSelection,
        EnrollmentStepButtons
    },
    mixins: [ EnrollmentSearchStartMomentsMixin ],
    data() {
        return {
            maxAmountOfStartmomentSelections: 2
        }
    },
    computed: {
        ...mapState('enrollmentModule', ['enrollmentType', 'formData', 'approvalRequired']),
        ...mapGetters('enrollmentModule', [
            'selectedStartMomentIds', 
            'selectedStartMoments', 
            'selectedStudents', 
            'copiedEnrollment',
            'course'
        ]),
        ...mapGetters('startMomentsModule', ['orders']),
        ...mapGetters('accountModule', ['firstStartMomentDate', 'finalStartMomentDate']),
        showBackButton() {
            return this.enrollmentType === enrollmentTypes.TEAMLEADER
        },
        showNextStepButton() {
            return this.selectedStartMoments.length && this.selectedStartMoments.length < this.maxAmountOfStartmomentSelections
        },
        preSelectedStartMomentId() {
            return this.$route.query?.startMoment
        },        
        firstStartMomentDateTime() {
            return DateUtils.formatDateTime(this.firstStartMomentDate, true)
        },
        finalStartMomentDateTime() {
            return DateUtils.formatDateTime(this.finalStartMomentDate, true)
        },
        formattedFirstStartMomentDate() {
            return DateUtils.parseToISOString(this.firstStartMomentDateTime)
        },
        formattedFinalStartMomentDate() {
            return DateUtils.parseToISOString(this.finalStartMomentDateTime)
        }
    },
    async activated() {
        if (this.selectedStartMoments?.length) {
            await this.getCoursePrice()
            await this.initializeStartMoments()
        }
    },
    async mounted() {
        if (this.enrollmentType === enrollmentTypes.STUDENT) {
            this.$gtag.pageview({ page_path: '/inschrijven-student-startmoments' })
        }

        if (this.enrollmentType === enrollmentTypes.TEAMLEADER)
            this.$gtag.pageview({ page_path: '/inschrijven-teamlead-startmoments' })

        if (this.enrollmentType !== enrollmentTypes.TEAMLEADER ){
            await this.initializeStartMoments()
            await this.setStartMoment()
        }

        if (this.copiedEnrollment) {
            await this.setCopiedEnrollmentStartMoments()
        }
    },
    methods: {
        ...mapMutations('enrollmentModule', [
            'setSelectedStartMoment',
            'clearSelectedStartMoment', 
            'clearStartMoments'
        ]),
        ...mapActions('enrollmentModule', ['getCoursePrice', 'checkApprovalRequired']),
    
        addStartMomentDateDynamicFiltersIfApplies(dataQuery) {
            const startMomentFilterBeginDate = this.formattedFirstStartMomentDate ? this.formattedFirstStartMomentDate : DateUtils.parseToISOString(DateUtils.today)
            dataQuery.addDynamicFilter(` fullDate ge ${startMomentFilterBeginDate}`)
            
            if (this.formattedFinalStartMomentDate) {
                dataQuery.addDynamicFilter(` fullDate lt ${this.formattedFinalStartMomentDate}`)
            }
        },
        async setStartMoment() {
            if(!this.preSelectedStartMomentId) return

            try {
                this.SET_IS_SEARCHING_START_MOMENTS(true)
                courseClient.cancelSearchStartMoments()
                
                const dataQuery = new SearchQueryBuilder()
                    .setTop(1)
                    .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, this.preSelectedStartMomentId))
                    .addFilter(new ODataQueryFilter('productReference', ODataFilterOperations.EQUALS, `course:${this.courseId}`))
                    .addFilter(new ODataQueryFilter('assortmentId', ODataFilterOperations.EQUALS, this.courseAssortment))

                this.addStartMomentDateDynamicFiltersIfApplies(dataQuery)

                const { results } = await courseClient.searchStartMoments(dataQuery.build())
               
                this.preSelectStartMomentIfValid(results, this.preSelectedStartMomentId)
            } catch (error) {
                if (!axios.isCancel(error)) {
                    throw new Error(`Failed to search for start moment to prefill with error: ${error}`)
                }
            } finally {
                this.SET_IS_SEARCHING_START_MOMENTS(false)
            }
        },
        async setCopiedEnrollmentStartMoments() {
            try {
                this.SET_IS_SEARCHING_START_MOMENTS(true)
                courseClient.cancelSearchStartMoments()

                const { eduMSFirstStartMomentId, eduMSSecondStartMomentId } = this.copiedEnrollment ?? {}
                if (!eduMSFirstStartMomentId) return

                const dataQuery = new SearchQueryBuilder()
                    .setTop(1)
                    .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, eduMSFirstStartMomentId))

                if (eduMSSecondStartMomentId) {
                    dataQuery
                        .setTop(2)
                        .or()
                        .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, eduMSSecondStartMomentId))
                }
                
                this.addStartMomentDateDynamicFiltersIfApplies(dataQuery)

                const { results } = await courseClient.searchStartMoments(dataQuery.build())

                this.preSelectStartMomentIfValid(results, eduMSFirstStartMomentId)
                this.preSelectStartMomentIfValid(results, eduMSSecondStartMomentId)
            } catch (error) {
                if (!axios.isCancel(error)) {
                    throw new Error(`Failed to search for copied start moments with error: ${error}`)
                }
            } finally {
                this.SET_IS_SEARCHING_START_MOMENTS(false)
            }
        },
        preSelectStartMomentIfValid(startMoments, externalId) {
            if (!externalId) return
    
            const validStartMoment = startMoments?.find(startMoment => startMoment.externalId === externalId)
            if (validStartMoment && !this.selectedStartMomentIds?.includes(validStartMoment.externalId)) {
                const startMomentWithOrders = startMomentHelper.addOrderTotalsToStartMoment(validStartMoment, this.orders)
                const isFullyBooked = this.limitIncompanyEnrollmentsAndIsIncompany && startMomentWithOrders?.hasReachedMaxCapacity
            
                if(!isFullyBooked) this.setSelectedStartMoment(startMomentHelper.mapStartMoment(startMomentWithOrders))
            }
        },
        selectStartMoment(startMoment) {
            if (startMoment) {
                this.setSelectedStartMoment(startMoment)
            }
            return null
        },
        async nextStep() {
            await this.checkApprovalRequired(this.selectedStartMomentIds)
            // If startmoments are changed while navigating back and forth, clear the assigned approver and related info if not applicable
            const isBudgetApprovalNeeded = this.formData?.budgets?.some(budget => budget.approvalRequired && budget.selectedAmount > 0)
            if(!this.approvalRequired && !isBudgetApprovalNeeded) {
                this.formData.assignedApproverId = null
            }
            this.$emit('next-step')
        }
    }
}
</script>

