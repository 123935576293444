import { mapActions, mapGetters, mapMutations } from "vuex"
import startMomentHelper from '../modules/startMomentHelper'
import courseClient from '../api/courseClient'
import SearchQueryBuilder from '../api/OData/searchQueryBuilder'
import ODataQueryFilter from '../api/OData/odataQueryFilter'
import ODataFilterOperations from '../api/OData/odataFilterOperations'
import DateUtils from '../utils/DateUtils'

const mixin = {
    computed: {
        ...mapGetters('startMomentsModule', [
            'startMoments', 
            'assortmentId', 
            'courseId',
            'totalCount',
            'pageNumber',
            'facets',
            'orders',
            'top',
            'showNoStartMomentsMessage'
        ]),
        ...mapGetters({
            isFetchingStartmoments: 'startMomentsModule/isLoading'
        }),
        ...mapGetters('accountModule', ['firstStartMomentDate', 'finalStartMomentDate'])
    },
    beforeDestroy() {
        this.RESET_STATE()
    },
    methods: {
        ...mapActions('startMomentsModule', [ 
            'initializeStartMomentsAndOrders',
            'setFacetsAndSearchStartMoments',
            'goToPage'
        ]),
        ...mapMutations('startMomentsModule', ['SET_IS_SEARCHING_START_MOMENTS', 'RESET_STATE']),
        getDataQueryForChosenStartMoments(firstStartMomentId, secondStartMomentId) {
            const dataQuery = new SearchQueryBuilder()
                .setTop(firstStartMomentId && secondStartMomentId ? 2 : 1)
                .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, firstStartMomentId))
        
            if (secondStartMomentId) {
                dataQuery
                    .or()
                    .addFilter(new ODataQueryFilter('externalId', ODataFilterOperations.EQUALS, secondStartMomentId))
            }
        
            dataQuery
                .and()
                .addFilter(new ODataQueryFilter('productReference', ODataFilterOperations.EQUALS, `course:${this.courseId}`))
                .addFilter(new ODataQueryFilter('assortmentId', ODataFilterOperations.EQUALS, this.assortmentId))
                .addDynamicFilter(`fullDate ge ${DateUtils.parseToISOString(DateUtils.today)}`)

            return dataQuery
        },
        fillInSelectedStartMomentIds(firstStartMomentId, selectedStartMomentIds, secondStartMomentId) {
            if (firstStartMomentId) selectedStartMomentIds.push(firstStartMomentId)
            if (secondStartMomentId) selectedStartMomentIds.push(secondStartMomentId)
        },
        async getChosenStartMoments(firstStartMomentId, secondStartMomentId, includeFirstStartMomentDateFilter = false, includeFinalStartMomentDateFilter = false) {
            if(!firstStartMomentId && !secondStartMomentId) return []

            try {
                this.SET_IS_SEARCHING_START_MOMENTS(true)

                const dataQuery = this.getDataQueryForChosenStartMoments(firstStartMomentId, secondStartMomentId)

                const { results } = await courseClient.searchStartMoments(dataQuery.build())
                  
                if(results?.length) {
                    let mappedStartMoments = startMomentHelper.mapStartMoments(results, this.limitIncompanyEnrollmentsAndIsIncompany, this.productType)
                    const selectedStartMomentIds = []
                    this.fillInSelectedStartMomentIds(firstStartMomentId, selectedStartMomentIds, secondStartMomentId)

                    // Put the first and second startmoments in the right order
                    mappedStartMoments = selectedStartMomentIds.map(smId => mappedStartMoments.find(sm => sm.startMomentId === smId))
                    
                    if (this.firstStartMomentDate && includeFirstStartMomentDateFilter) {
                        mappedStartMoments = mappedStartMoments.filter(sm => sm.fullDate >= this.firstStartMomentDate)
                    }
                    if (this.finalStartMomentDate && includeFinalStartMomentDateFilter) {
                        mappedStartMoments = mappedStartMoments.filter(sm => sm.fullDate < this.finalStartMomentDate)
                    }
                    const startMomentsWithOrders = startMomentHelper.addOrderTotalsToStartMoments(mappedStartMoments, this.orders)
                    
                    return startMomentsWithOrders
                }

                return []
            } catch (error) {
                throw new Error(error)
            } finally {
                this.SET_IS_SEARCHING_START_MOMENTS(false)
            }
        }
    }
}

export default mixin
