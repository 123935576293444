import axios from 'axios'
import courseClient from '../../api/courseClient'
import geoLocationClient from '../../api/geoLocationClient'
import aiClient from '../../api/aiClient'
import * as courseVariants from '../../constants/courseVariants'
import * as courseSearchFacets from '../../constants/facetKeys'
import FacetUtils from '../../utils/FacetUtils'

const searchModule = {
    namespaced: true,

    state() {
        return {
            items: [],
            facets: [],
            count: 0,
            query: '',
            selectedFilters: [],
            userFilters: [],
            locationRadiusFilters: [],
            selectedPeersFilter: null,
            isLoading: false,
            skip: 0,
            pageSize: 20,
            sorting: '',
            isColumnView: true,
            searchFromTeamAssortments: false,
            locationFilterCity: null,
            locationFilterRadius: null,
            eLearningLocations: [],
            includeLocationRadiusFilter: false,
            hiddenFacets: [courseSearchFacets.STARTMOMENTYEARANDMONTHANDCITY],
            useSmartSearch: false,
            isSmartSearchStickyVisible: false,
            smartSearchQuery: null
        }
    },
    getters: {
        isSelectedFilter: state => (facetName, filterValue) => {
            return state.selectedFilters.some(f => {
                if (f.name === facetName) {
                    return f.values.some(v => v === filterValue)
                }
                return false
            })
        },
        searchTitle: state => state.query,
        isColumnView: state => state.isColumnView,
        searchFromTeamAssortments: state => state.searchFromTeamAssortments,
        isLoading: state => state.isLoading,
        selectedLocationRadiusFilters: state => {
            // when we do course search with location and radius
            // course search result should always include e-learning locations
            const locationRadiusFilters = Object.assign([], state.locationRadiusFilters)
            locationRadiusFilters?.push(...state.eLearningLocations)
            // get unique list 
            const locationFilter = [... new Set(locationRadiusFilters)]

            return {
                name: courseSearchFacets.LOCATIONS,
                values: locationFilter
            }
        },
        facets: state => state.facets
    },
    mutations: {
        resetState(state, resetSmartSearch = true) {
            state.items = []
            state.facets = []
            state.count = 0
            state.query = ''
            state.selectedFilters = []
            state.userFilters = []
            state.selectedPeersFilter = null
            state.isLoading = false
            state.skip = 0
            state.searchFromTeamAssortments = false

            if (resetSmartSearch) {
                state.useSmartSearch = false
                state.isSmartSearchStickyVisible = false
                state.smartSearchQuery = null
            }
        },
        initializeSessionProps(state) {
            // if the isColumnView was saved before, in this current session, assign that value to the store state
            if (sessionStorage.getItem('searchModule/isColumnView')) {
                state.isColumnView = JSON.parse(sessionStorage.getItem('searchModule/isColumnView'))
            }
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setItems(state, newItems) {
            state.items = newItems
        },
        pushItems(state, newItems) {
            state.items = [...state.items, ...newItems]
        },
        setFacets(state, newFacets) {
            state.facets = newFacets
        },
        setQuery(state, newQuery) {
            state.query = newQuery
        },
        setCount(state, newCount) {
            state.count = newCount
        },
        setSkip(state, newSkip) {
            state.skip = newSkip
        },
        setPageSize(state, newPageSize) {
            state.pageSize = newPageSize
        },
        setUserFilters(state, userFilters) {
            state.userFilters = userFilters
        },
        setSorting(state, newSorting) {
            state.sorting = newSorting
        },
        setSearchFromTeamAssortments(state, searchFromTeamAssortments) {
            state.searchFromTeamAssortments = searchFromTeamAssortments
        },
        addFilter(state, { facetName, filterValue }) {
            const facetToUpdate = state.selectedFilters.find(f => f.name === facetName)
            if (!facetToUpdate) {
                state.selectedFilters.push({ name: facetName, values: [filterValue] })
            } else {
                facetToUpdate.values.push(filterValue)
            }
        },
        removeFilter(state, { facetName, filterValue }) {
            const facetToUpdate = state.selectedFilters.find(f => f.name === facetName)
            if (facetToUpdate) {
                facetToUpdate.values = facetToUpdate.values.filter(v => v !== filterValue)
            }

            // Delete facet from selectedfilters if no selected filters are left
            if (!facetToUpdate?.values.length) {
                state.selectedFilters = state.selectedFilters.filter(f => f.name !== facetName)
            }
        },
        removeFilters(state) {
            state.selectedFilters = []
        },
        setIsColumnView(state, isColumnView) {
            state.isColumnView = isColumnView
            sessionStorage.setItem('searchModule/isColumnView', JSON.stringify(isColumnView))
        },
        setSelectedPeersFilter(state, selectedPeersFilter) {
            state.selectedPeersFilter = selectedPeersFilter
        },
        setLocationRadiusFilter(state, cities) {
            state.locationRadiusFilters = cities
        },
        setLocationFilterCity(state, city) {
            state.locationFilterCity = city
        },
        setLocationFilterRadius(state, radius) {
            state.locationFilterRadius = radius
        },
        setELearningLocations(state, locations) {
            state.eLearningLocations = locations
        },
        setIncludeLocationRadiusFilter(state, includeLocationRadiusFilter) {
            state.includeLocationRadiusFilter = includeLocationRadiusFilter
        },
        setUseSmartSearch(state, value) {
            state.useSmartSearch = value
        },
        setSmartSearchStickyVisible(state, value) {
            state.isSmartSearchStickyVisible = value
        },
        setSmartSearchQuery(state, value) {
            state.smartSearchQuery = value
        }
    },
    actions: {
        async resetLocationRadiusFilters({ commit, rootGetters }, keepCityEmpty = false) {
            commit('setIncludeLocationRadiusFilter', false)
            commit('setLocationRadiusFilter', [])
            commit('setLocationFilterRadius', null)
            let cityValue = ''

            if (!keepCityEmpty) {
                const userCity = rootGetters['userModule/city']
                const response = await geoLocationClient.getCityList(userCity)
                cityValue = response?.findIndex(element => element.toLowerCase() === userCity.toLowerCase()) > -1 ? userCity : ''
            }

            commit('setLocationFilterCity', cityValue)
        },
        async fetchResults({ commit, state, getters, rootGetters }, isTriggeredByPagination = false) {
            try {
                commit('setIsLoading', true)

                const query = state.query
                const top = state.pageSize
                const skip = state.skip
                const peersFilter = state.selectedPeersFilter
                const sorts = state.sorting
                const userIds = state.userFilters
                const searchFromTeamAssortments = state.searchFromTeamAssortments
                const smartSearchQuery = state.smartSearchQuery

                let filters = [...state.selectedFilters]

                if (state.includeLocationRadiusFilter && getters.selectedLocationRadiusFilters?.values) {
                    filters = [...filters, { ...getters.selectedLocationRadiusFilters }]
                }

                // Handle composite filters 
                const isCompositeFacetSelected = FacetUtils.isCompositeFacetSelected(filters)
                let uniqueSelectedLocationFilters
                let selectedStartMomentsFilters

                if (isCompositeFacetSelected) {
                    uniqueSelectedLocationFilters = FacetUtils.getFacetSelectedValues(filters, courseSearchFacets.LOCATIONS)
                    selectedStartMomentsFilters = FacetUtils.getFacetSelectedValues(filters, courseSearchFacets.STARTMOMENTS)

                    if (uniqueSelectedLocationFilters?.length && selectedStartMomentsFilters?.length) {
                        filters = FacetUtils.addYearAndMonthAndCityFilter(filters, selectedStartMomentsFilters, uniqueSelectedLocationFilters)
                    }
                }

                // Add if first startmoment filter is to be included in search filter
                const includeFirstStartMomentDateFilter = !!rootGetters['accountModule/firstStartMomentDate']

                // Add if final startmoment filter is to be included in search filter
                const includeFinalStartMomentDateFilter = !!rootGetters['accountModule/finalStartMomentDate']

                let queryOrSynonyms = smartSearchQuery || query
                if (state.useSmartSearch && !smartSearchQuery && query) {
                    queryOrSynonyms = await aiClient.getSynonyms(query)
                    commit('setSmartSearchQuery', queryOrSynonyms)
                }

                const response = await courseClient.fetchSearch({
                    query: queryOrSynonyms,
                    top,
                    skip,
                    filters,
                    peersFilter,
                    sorts,
                    userIds,
                    searchFromTeamAssortments,
                    includeFirstStartMomentDateFilter,
                    includeFinalStartMomentDateFilter,
                    includeCoursesOutsideAssortmentFilter: true
                })

                const firstStartMomentDate = rootGetters['accountModule/firstStartMomentDate']
                const finalStartMomentDate = rootGetters['accountModule/finalStartMomentDate']
                let facets = FacetUtils.filterFacetsBasedOnFirstAndFinalSmDates(response?.data?.facets, firstStartMomentDate, finalStartMomentDate)

                // Filter out facets startMoments/locations values that don't exist
                if (uniqueSelectedLocationFilters?.length || selectedStartMomentsFilters?.length) {
                    const locationsWithExistingStartMoment = FacetUtils.getExistingLocationsOrStartMoments(selectedStartMomentsFilters, facets, courseSearchFacets.LOCATIONS)
                    const startMomentsWithExistingLocations = FacetUtils.getExistingLocationsOrStartMoments(uniqueSelectedLocationFilters, facets, courseSearchFacets.STARTMOMENTS)

                    // Filter out facet values
                    facets = facets?.map(f => {
                        if (f.key === courseSearchFacets.LOCATIONS && selectedStartMomentsFilters?.length) {
                            const existingValues = FacetUtils.getExistingValues(f.values, locationsWithExistingStartMoment)
                            const notExistingValues = FacetUtils.getNotExistingValues(existingValues, uniqueSelectedLocationFilters)

                            // Remove not existing values from the selected filters
                            notExistingValues.forEach(filterValue => {
                                commit('removeFilter', { facetName: f.key, filterValue })
                            })
                            f.values = existingValues
                        }

                        if (f.key === courseSearchFacets.STARTMOMENTS && uniqueSelectedLocationFilters?.length) {
                            const existingValues = FacetUtils.getExistingValues(f.values, startMomentsWithExistingLocations)
                            const notExistingValues = FacetUtils.getNotExistingValues(existingValues, selectedStartMomentsFilters)

                            // Remove not existing values from the selected filter
                            notExistingValues.forEach(filterValue => {
                                commit('removeFilter', { facetName: f.key, filterValue })
                            })
                            f.values = existingValues
                        }

                        return f
                    })
                }

                commit('setFacets', facets)

                if (isTriggeredByPagination) commit('pushItems', response?.data?.results)
                else commit('setItems', response?.data?.results)

                commit('setCount', response?.data?.count)
                commit('setIsLoading', false)
                commit('setSmartSearchStickyVisible', !!query?.trim().length && !state.useSmartSearch)
            } catch (error) {
                if (axios.isCancel(error)) {
                    // Do nothing
                } else {
                    commit('setIsLoading', false)
                    throw new Error('Something went wrong while retrieving the search items', error)
                }
            }
        },
        async setFilter({ commit, getters, dispatch }, { facetName, filterValue, checked, fetchResults }) {
            if (facetName === courseSearchFacets.LOCATIONS) {
                await dispatch('resetLocationRadiusFilters', true)
            }

            if (checked && !getters.isSelectedFilter(facetName, filterValue)) {
                commit('addFilter', { facetName, filterValue })
            } else if (!checked && getters.isSelectedFilter(facetName, filterValue)) {
                commit('removeFilter', { facetName, filterValue })
            }

            if (fetchResults) {
                commit('setSkip', 0)
                dispatch('fetchResults')
            }
        },
        setPeersFilter({ commit, dispatch }, { filterValue, checked }) {

            commit('setSelectedPeersFilter', checked ? filterValue : '')

            commit('setSkip', 0)
            dispatch('fetchResults')
        },
        handlePageSize({ commit, dispatch }, pageSize) {
            commit('setSkip', 0)
            commit('setPageSize', pageSize)
            dispatch('fetchResults')
        },
        handleSorting({ commit, dispatch }, sorting) {
            commit('setSkip', 0)
            commit('setSorting', sorting)
            dispatch('fetchResults')
        },
        goToNextPage({ commit, state, dispatch }) {
            commit('setSkip', state.skip + state.pageSize)
            dispatch('fetchResults', true)
        },
        async fetchELearningLocations({ commit, state }) {

            if (state.eLearningLocations?.length > 0) return

            const eLearningVariantFilter = [{ name: courseSearchFacets.VARIANTS, values: [courseVariants.ELEARNINGVARIANT] }]

            courseClient
                .fetchSearch({ filters: eLearningVariantFilter })
                .then(response => {
                    if (response) {
                        const locationFacet = response?.data?.facets?.find(facet => facet.key === courseSearchFacets.LOCATIONS)
                        const eLearningLocations = locationFacet?.values?.map(location => { return location.key })

                        commit('setELearningLocations', eLearningLocations)
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        // Do nothing
                    } else {
                        console.error(
                            'Something went wrong while retrieving eLearning Locations',
                            error
                        )
                    }
                })
        }
    }
}

export default searchModule
