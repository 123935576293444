<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <h2>Inschrijving verifiëren</h2>
        <p class="mb-0">Verifiëer hier uw inschrijving. U kunt uw gegevens toevoegen en/of aanvullen. Het is belangrijk dat uw persoonlijke gegevens correct zijn, daar deze worden gebruikt voor eventuele certificaten of diploma's. De opleider zal eventueel studiemateriaal naar het opgegeven adres versturen.</p>
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <loader-component
        v-if="isLoading"
        overlay="white"
        color="primary"
        is-fixed
      />
    </transition>

    <template v-if="enrollment">
      <template v-if="$isAllowed('verifyEnrollment', enrollment)">
        <recess-divider />
  
        <router-link 
          data-test="course-link"
          target="_blank"
          :to="{ name: 'course', query: { course: enrollment.eduMSCourseId, assortment: enrollment.assortmentId }}"
        >
          <h3 class="mb-0">{{ courseTitle }}</h3>
        </router-link>
  
        <template v-if="startMoments && startMoments.length">
          <recess-divider />
  
          <!-- Startmoments and locations -->
          <start-moment-selection
            :course="course"
            :start-moments="startMoments"
            :selected-start-moments="selectedStartMoments"
            :startmoment-updates-enabled="startmomentUpdatesEnabled"
            :assortment-id="assortmentId"
            :facets="facets"
            :total-count="totalCount"
            :page-number="pageNumber"
            :page-size="top"
            :show-capacity="showCapacity"
            :show-no-start-moments-message="showNoStartMomentsMessage"
            @pagination-clicked="pageNumber => goToPage({ pageNumber, shouldFetchOrders: showCapacity })"
            @select-start-moment="startMoment => selectNewStartMoment(startMoment)"
            @clear-start-moment="clearStartMoment"
            @clear-start-moments="clearStartMoments"
            @facet-change="selectedFacets => setFacetsAndSearchStartMoments({selectedFacets, shouldFetchOrders: showCapacity })"
          />
        </template>
  
        <!-- Personal data -->
        <enrollment-verification-personal-data ref="personalDataForm" />
        
        <!-- Learning result -->
        <enrollment-verification-learning-result-sharing v-if="hasLearningResults" />

        <recess-button
          :url="{ name: 'my-development', query: {'my-development-tab': myDevelopmentTabs.ENROLLMENTS.key, tableDataQueries: $route.query && $route.query.tableDataQueries || undefined } }"
          title="Terug"
          class="col-sm-12 col-md-auto mt-3 mb-3 mb-md-0"
          variant="tertiary"
        />

        <recess-button
          :disabled="actionButtonsDisabled || (!isFirstStartMomentValid || !isSecondStartMomentValid) || (isFirstStartMomentFullyBooked && limitIncompanyEnrollmentsAndIsIncompany)"
          data-test="verify-button"
          title="Verifiëren"
          class="u-position-right col-sm-12 col-md-auto mt-3 mb-3 mb-md-0 ml-4"
          variant="primary"
          @click="checkDataAndSubmit"
        />

        <recess-button
          :disabled="actionButtonsDisabled"
          data-test="decline-verify-button"
          title="Afwijzen"
          class="u-position-right col-sm-12 col-md-auto mt-3  mb-3 mb-md-0"
          variant="secondary"
          @click="showDeclineModal = true"
        />
      </template>

      <!-- Show message if enrollment has already been verified or try to access the page -->
      <div v-if="!isLoading">
        <recess-alert
          v-if="!$isAllowed('verifyEnrollment', enrollment) && !isLoading && !isError"
          class="mt-5"
          text="Deze inschrijving is al gecontroleerd en ingezonden. Er is op dit moment geen actie vereist."
        />

        <!-- If the first start moment is a valid start moment, but it has reached the max capacity, show error that the start moment is fully booked -->
        <template v-if="!isFirstStartMomentValid || !isSecondStartMomentValid">
          <recess-divider />
          <recess-alert
            data-test="error-startmoment-invalid"
            type="error"
            class="mt-5"
            text="Minstens een van de gekozen startmomenten bestaat niet (meer) binnen deze ontwikkelactiviteit. 
            Indien mogelijk kunt u nieuwe startmomenten kiezen. Kan dat niet, dan kunt u de inschrijving annuleren en een nieuwe inschrijving doen met geldige startmomenten."
          />
        </template>
  
        <template v-if="isFirstStartMomentFullyBooked && limitIncompanyEnrollmentsAndIsIncompany">
          <recess-divider />
          <recess-alert
            data-test="error-fully-booked-startmoment"
            type="error"
            class="mt-5"
            text="Het gekozen eerste startmoment is volgeboekt. Kies een ander startmoment om de inschrijving te kunnen vervolgen."
          />
        </template>
      </div>

      <!-- Modals -->
      <enrollment-verification-confirmation-modal
        :thank-you-modal-visibility="thankYouModalVisibility" 
      />
      <enrollment-verification-decline-modal
        v-if="showDeclineModal"
        data-test="enrollment-verification-decline-modal"
        @close="showDeclineModal = false"
        @disable-action-buttons="actionButtonsDisabled = true"
      />
      <enrollment-save-personal-data-modal
        :show-save-personal-data-modal="showSavePersonalDataModal"
        data-test="save-personal-data-modal"
        @close="showSavePersonalDataModal = false"
        @submit="setUpdateUserDataAndVerify"
      />
    </template>

    <!-- Show error alert if something went wrong  -->
    <recess-alert
      v-if="isError && !isLoading"
      type="error"
      class="mt-5"
      text="Er is iets mis gegaan. Probeer het later opnieuw."
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import StartMomentSelection from '../../StartMomentSelection/StartMomentSelection.vue' 
import EnrollmentVerificationPersonalData from './EnrolmentVerificationPersonalData.vue'
import enrollmentPerimeter from '../../../../perimeters/enrollmentPerimeter'
import { myDevelopmentTabs } from '../../../../constants/tabNames'
import EnrollmentVerificationDeclineModal from './EnrolmentVerificationDeclineModal.vue'
import EnrollmentSavePersonalDataModal from '../EnrollmentComponent/EnrollmentSavePersonalDataModal.vue'
import EnrollmentVerificationLearningResultSharing from './EnrollmentVerificationLearningResultSharing.vue'
import EnrollmentVerificationConfirmationModal from './EnrollmentVerificationConfirmationModal.vue'
import EnrollmentDetailStartMomentsMixin from '../../../../mixins/enrollment-detail-start-moments-mixin'
import LearningResultSharingTypesEnum from '../../../../constants/learningResultSharingTypeEnum'
import featuresPerimeter from '../../../../perimeters/featuresPerimeter'

export default {
    components: {
        LoaderComponent,
        StartMomentSelection,
        EnrollmentVerificationPersonalData,
        EnrollmentVerificationConfirmationModal,
        EnrollmentSavePersonalDataModal,
        EnrollmentVerificationDeclineModal,
        EnrollmentVerificationLearningResultSharing
    },
    mixins: [ EnrollmentDetailStartMomentsMixin ],
    perimeters: [enrollmentPerimeter, featuresPerimeter],
    data() {
        return {
            thankYouModalVisibility: false,
            actionButtonsDisabled: false,
            showDeclineModal: false,
            showSavePersonalDataModal: false,
            myDevelopmentTabs
        }
    },
    computed: {
        ...mapGetters('verificationEnrollmentModule', [
            'selectedStartMoments',
            'startmomentUpdatesEnabled',
            'isFirstStartMomentValid', 
            'isSecondStartMomentValid',
            'isFirstStartMomentFullyBooked', 
            'limitIncompanyEnrollmentsAndIsIncompany', 
            'enrollmentTeamLeaderLearningResult',
            'firstSelectedStartMomentId',
            'secondSelectedStartMomentId',
            'course',
            'showCapacity',
            'willUpdateUserData'
        ]),
        ...mapGetters('accountModule', [ 'isLearningResultEnabled']),
        ...mapState('verificationEnrollmentModule', [
            'enrollment',
            'courseTitle',
            'isFetchingCourse',
            'isFetchingEnrollment',
            'isFetchingCountries',
            'isError',
            'isVerifyingEnrollment'
        ]),
        ...mapState('userModule', ['user']),
        hasLearningResults() {
            return this.isLearningResultEnabled && this.enrollmentTeamLeaderLearningResult && this.enrollmentTeamLeaderLearningResult.learningResultSharingType !== LearningResultSharingTypesEnum.None
        }, 

        isLoading() {
            return (
                this.isFetchingCourse ||
        this.isFetchingEnrollment ||
        this.isFetchingCountries ||
        this.isVerifyingEnrollment ||
        this.isFetchingStartmoments
            )
        },
        enrollmentUserData() {
            return {
                firstName: this.enrollment.firstName,
                middleName: this.enrollment.middleName,
                lastName: this.enrollment.lastName,
                birthday: this.isBirthDateInputDisplayed ? this.enrollment.birthDay : null,
                birthplace: this.enrollment.birthPlace,     
                telephone: this.enrollment.phoneNumber,
                postalCode: this.enrollment.addressPostalCode,
                number: this.enrollment.addressHouseNumber,
                numberAdditive: this.enrollment.addressHouseNumberAdditive,
                street: this.enrollment.addressStreet,
                city: this.enrollment.addressCity,
                country: this.enrollment.addressCountryCode
            }
        },
        hasUserDataChanged() {
            // Check if any of the values from the enrollment data is different from what is in the user module
            if (!this.isBirthDateInputDisplayed) {
                return Object.entries(this.enrollmentUserData).some(([key, value]) => key !== 'birthday' && this.user[key] !== value)
            }
            return Object.entries(this.enrollmentUserData).some(([key, value]) => this.user[key] !== value )
        },
        isBirthDateInputDisplayed() {
            return this.$isAllowed('displayPersonalInformation') && this.$isAllowed('displayBirthDateField')
        }
    },
    beforeDestroy() {
        this.resetState()
    },
    created() {
        this.initialize()
    },
    methods: {
        ...mapActions('verificationEnrollmentModule', ['getEnrollment', 'verifyEnrollment']),
        ...mapMutations('verificationEnrollmentModule', [
            'clearStartMoment',
            'clearStartMoments',
            'setSelectedStartMoment',
            'setWillUpdateUserData',
            'setSelectedStartMoment',
            'selectNewStartMoment',
            'resetState'
        ]),
        ...mapActions('userModule', ['updateUser']),
        ...mapMutations('userModule', ['updateUserFields']),
       
        async initialize() {
            this.clearStartMoments()
            const enrollmentId = this.$route.query?.enrollment || null
            await this.getEnrollment(enrollmentId)
          
            if (this.enrollment?.eduMSFirstStartMomentId || this.enrollment?.eduMSSecondStartMomentId) {
                await this.initializeStartMomentsAndOrders({ 
                    courseId: this.enrollment.eduMSCourseId, 
                    assortmentId: this.enrollment.assortmentId,
                    shouldFetchOrders: this.showCapacity
                })
                await this.initializePreselectedStartMoments()
            }
        },
        async initializePreselectedStartMoments() {
            if(this.isFirstStartMomentValid || this.isSecondStartMomentValid) {
                const preSelectedStartMoments = await this.getChosenStartMoments(this.firstSelectedStartMomentId, this.secondSelectedStartMomentId, true, true)

                preSelectedStartMoments.forEach(startMoment => {
                    if (startMoment) this.setSelectedStartMoment(startMoment)
                })
            }          
        },
        async checkDataAndSubmit() {
            this.$refs.personalDataForm.$v.$touch()
            if (this.$refs.personalDataForm.$v.$invalid || !this.isFirstStartMomentValid || !this.isSecondStartMomentValid) {
                return
            }

            if (this.hasUserDataChanged) {
                this.showSavePersonalDataModal = true
                return
            }

            await this.verify()
        },
        async setUpdateUserDataAndVerify(emittedData) {
            this.showSavePersonalDataModal = false
            
            // Set the willUpdateUserData flag in the verification module and update the user properties in the user module
            if (emittedData.willUpdateUserData) {
                this.setWillUpdateUserData(emittedData.willUpdateUserData)
                this.updateUserFields(this.enrollmentUserData)
            }

            await this.verify()
        },
        async verify() {
            try {
                await this.verifyEnrollment()
                // Disable the submit button so the user cannot try to verify again after clicking away the modal.
                this.actionButtonsDisabled = true
                this.thankYouModalVisibility = true
            } catch (error) {
                throw new Error(error)
            } finally {
                // At the end, make sure to update the user. If this request fails, it's not a big deal. That's why it's outside of the try/catch
                if(this.willUpdateUserData) this.updateUser(this.isBirthDateInputDisplayed)
            }

        }
    }
}
</script>
