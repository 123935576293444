<template>
  <div 
    class="c-searchsuggestions d-md-flex justify-content-between"
    data-test="banner-search-box"
  >
    <div class="container-fluid w-100 p-0">
      <div class="d-flex flex-wrap flex-lg-nowrap">
        <div class="col-lg-6 col-12 px-0 mb-lg-0 mb-2">
          <recess-input
            v-model="searchQuery"
            placeholder-text="Zoek ontwikkelactiviteit"
            data-test="banner-search-input"
            @keydown.native.enter="submitSearch"
          />
          <autocomplete-list
            v-click-outside="closeSuggestions"
            :suggestions="suggestions"
            :search-query="searchQuery"
            :show="showSuggestions"
            :show-close-button="true"
            title="Suggesties"
            class="d-md-none d-lg-block"
            button-title="Bekijk alle ontwikkelactiviteiten"
            @close="closeSuggestions"
            @itemSelected="suggestion => goToSuggestion(suggestion)"
          >
            <template
              slot="item"
              slot-scope="props"
            >
              <span>{{ props.item.title }}</span>
            </template>
          </autocomplete-list>
        </div>

        <div class="col-lg-5 col-10 px-lg-2 px-0">
          <recess-multi-select
            v-model="variantFilters"
            select-options-text="Ontwikkelactiviteitsvariant"
            :options="variantsOptions"
            data-test="banner-search-variant"
          />
        </div>

        <div class="col-lg-1 col-2 px-lg-0 pl-2 pr-md-0 pr-0">
          <div
            class="c-searchinput__button--custom"
            data-test="banner-search-button"
            @click="submitSearch"
            @keydown.enter="submitSearch"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapGetters, mapState } from 'vuex'
import facetFilterHelper from '../../../modules/facetFilterHelper'
import mixinClickOutside from '../../../mixins/click-outside-directive'
import AutocompleteList from '../../molecules/AutocompleteList/AutocompleteList.vue'
import courseClient from '../../../api/courseClient'
import * as searchTypes from '../../../constants/searchTypes'
import routePerimeter from '../../../perimeters/routePerimeter'

export default {
    name: 'SuggestionBannerComponent',
    components: { AutocompleteList },
    perimeters: [ routePerimeter ],
    mixins: [mixinClickOutside],
    data() {
        return {
            showSuggestions: false,
            searchQuery: '',
            suggestions: [],
            variantFilters: []
        }
    },
    computed: {
        ...mapState("searchModule", ["searchFromTeamAssortments"]),
        ...mapGetters('facetsModule', ['variants']),
        ...mapGetters('accountModule', ['firstStartMomentDate', 'finalStartMomentDate']),
        variantsOptions() {
            return this.variants?.values?.map(variant => {
                return {
                    displayText: variant,
                    value: variant
                }
            })
        }
    },
    watch: {
        async searchQuery(newValue) {
            try {
                // Check if searchquery contains at least three characters
                if (newValue && newValue.length >= 3) {
                    const suggestRequestData = {
                        query: newValue,
                        top: 5,
                        includeFirstStartMomentDateFilter: !!this.firstStartMomentDate,
                        includeFinalStartMomentDateFilter: !!this.finalStartMomentDate
                    }
    
                    if (this.variantFilters?.length) {
                        suggestRequestData.filters = [
                            {
                                name: 'variants_nl',
                                values: this.variantFilters
                            }
                        ]
                    }

                    const suggestionResponse = await courseClient.fetchSearchSuggestions(suggestRequestData)

                    this.suggestions = suggestionResponse?.data?.results ?? []

                    if (this.suggestions.length)  this.showSuggestions = true
                    else  this.closeSuggestions()
                } else {
                    this.closeSuggestions()
                }
                
            } catch (error) {
                if (axios.isCancel(error)) {
                    // Do nothing when previous request is cancelled
                } else {
                    console.error(error)
                }
            }
        }
    },
    methods: {
        ...mapMutations('searchModule', ['addFilter']),
        closeSuggestions() {
            this.showSuggestions = false
        },
        addSelectedFilters() {
            this.variantFilters.forEach(filter => {
                this.addFilter({
                    facetName: 'variants_nl',
                    filterValue: filter
                })
            })
        },
        async submitSearch() {
            let facetFilters
            let searchType 

            // Cancel the latest request before routing to the results page
            courseClient.cancelFetchSearchSuggestions()

            if(this.$isAllowed('routeAsTeamleader')) searchType = this.searchFromTeamAssortments ? searchTypes.TEAMSEARCH : searchTypes.SELFSEARCH

            // push analytics
            this.$gtag.event('search', { search_term: this.searchQuery })
                    
            if (this.variantFilters?.length) {
                this.addSelectedFilters()
                facetFilters = await this.getEncodedVariantFilters()
            }

            this.closeSuggestions()

            await this.$router.push({
                name: 'search',
                query: {
                    ...this.$route.query,
                    q: this.searchQuery,
                    searchType,
                    facetFilters
                }
            })
        },
        async getEncodedVariantFilters() {
            let encodedVariantsFilters

            this.variantFilters.forEach(variant => {
                const mappedSelectedFacetFilter = {
                    key: this.variants.key,
                    value: variant,
                    checked: true
                }
                encodedVariantsFilters = facetFilterHelper.getFacetFiltersAsBase64(mappedSelectedFacetFilter, encodedVariantsFilters)
            })

            return encodedVariantsFilters
        },
        goToSuggestion(suggestion) {
            const productReference = suggestion?.productReference

            if (!productReference || productReference.indexOf(':') === -1) {
                return
            }

            const courseId = productReference.split(':')[1]
            const courseRoute = { name: 'course', query: { course: courseId, assortment: suggestion.assortmentId } }
            this.$router.push(courseRoute)

            this.closeSuggestions()

            if (courseRoute?.name === this.$route?.name) {
                this.$router.refreshPage()
            }
        }
    }
}
</script>
