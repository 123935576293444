<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <transition name="fade">
      <loader-component
        v-if="isLoading"
        overlay="white"
        is-fixed
        color="primary"
      />
    </transition>
    <div 
      v-if="course"
      data-test="course-page-content"
    >
      <!-- Course title -->
      <div
        class="py-5 u-bg--variant5"
      >
        <recess-layout-static>
          <div class="row">
            <div class="col-12 col-md-10">
              <h1
                v-if="course.name"
                class="mb-0"
                v-html="course.name"
              >
              </h1>
            </div>
          </div>
        </recess-layout-static>
      </div>

      <!-- Cannot enroll alert message -->
      <recess-layout-static>
        <recess-alert
          v-if="cannotEnrollMessage"
          class="my-5 "
          type="info"
          data-test="cannot-enroll-alert"
          :text="cannotEnrollMessage"
        />
      </recess-layout-static>

      <!-- Course content -->
      <recess-layout-static>
        <div class="row">
          <recess-divider />
          <!-- Course top-left summary  -->
          <div class="col-12 col-lg-4">
            <recess-card bordered>
              <recess-list class="p-0">
                <recess-list-row>
                  <div class="c-rating-holder d-flex align-items-center">
                    <feedback-stars-component :score="courseRating && courseRating.rating || 0" />

                    <span class="ml-2">({{ courseRating && courseRating.count || 0 }})</span>
                  </div>
                </recess-list-row>
              </recess-list>
              <recess-divider show-line />

              <course-summary-info :course="course" />

              <recess-divider
                v-if="$isAllowed('displayPricingInformation')"
                show-line
              />

              <price-summary-component
                v-if="$isAllowed('displayPricingInformation')"
              />

              <enrollment-button-component
                v-if="!isLoading"
                ref="enrollmentButton"
                :is-e-learning="isELearning"
                :course="enrollmentButtonData"
                class="mt-4"
              />

              <div class="c-course-actions d-flex justify-content-around pt-3">
                <wish-list-toggle-component
                  :course-id="course.id"
                  :is-on-wish-list="course.isOnWishlist"
                  class="c-course-actions__item"
                  is-column-view
                  @wish-list-toggled="updateUserWishList"
                />
                <div
                  v-if="$isAllowed('displayLearningPath')"
                  class="c-course-actions__item"
                  @click="toggleAssignLearningPath()"
                >
                  <recess-icon
                    has-hover
                    icon="plus"
                    class="flex-column u-text-small"
                  >
                    Leerpad
                  </recess-icon>
                </div>
                <!-- Toggle icons on hover -->
                <div
                  v-if="$isAllowed('displayShareCourse')"
                  class="c-course-actions__item"
                  @mouseover="showEmptyIcon = false"
                  @mouseleave="showEmptyIcon = true"
                  @touchstart.prevent="showRecommendCourseModal = true"
                  @click="showRecommendCourseModal = true"
                >
                  <recess-icon
                    :icon="showEmptyIcon ? 'share-empty' : 'share-full'"
                    has-hover
                    class="flex-column u-text-small"
                  >
                    Delen
                  </recess-icon>
                </div>
              </div>
            </recess-card>

            <recess-divider />

            <div
              v-if="$isAllowed('displayContactInformationForStudents') || $isAllowed('displayContactAlternativeText')"
              class="d-none d-lg-block"
            >
              <contact-teaser-component />
            </div>
          </div>

          <!-- content -->
          <div class="col-12 col-lg-8">
            <course-page-main :course="course" />
          </div>

          <div
            v-if="$isAllowed('displayContactInformationForStudents') || $isAllowed('displayContactAlternativeText')"
            class="col-12 d-lg-none"
          >
            <recess-divider />
            <contact-teaser-component />
          </div>
        </div>

        <recess-divider />
      </recess-layout-static>
      <assign-learning-path-course-component
        :show-assign-learning-path="showAssignLearningPath"
        :course-id="course.id"
        @close="closeAssignLearningPathModal()"
      />
      <recommend-course-component
        v-if="$isAllowed('displayShareCourse')"
        :course-name="course.name"
        :course-id="course.id"
        :assortment-id="courseAssortmentId"
        :show-recommend-course-modal="showRecommendCourseModal"
        @close="showRecommendCourseModal = false"
      />
    </div>

    <recess-layout-static>
      <recess-alert
        v-if="showErrorMessage"
        class="my-5"
        type="error"
        data-test="course-unavailable-error"
        text="Deze ontwikkelactiviteit is op dit moment niet (meer) beschikbaar. Excuus voor het ongemak."
      />
    </recess-layout-static>
    <create-learning-path-component />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import courseClient from '../../../api/courseClient'
import { postVisitedCourse } from '../../../api/visitedCoursesClient'
import * as courseLearningMethodTypes from '../../../constants/courseLearningMethodTypes'
import featuresPerimeter from '../../../perimeters/featuresPerimeter'
import learningPathPerimeter from '../../../perimeters/learningPathPerimeter'
import routePerimeter from '../../../perimeters/routePerimeter'
import LoaderComponent from '../../atoms/LoaderComponent/LoaderComponent.vue'
import ContactTeaserComponent from '../../molecules/ContactTeaserComponent/ContactTeaserComponent.vue'
import FeedbackStarsComponent from '../../molecules/FeedbackStarsComponent/FeedbackStarsComponent.vue'
import RecommendCourseComponent from '../../molecules/RecommendCouseComponent/RecommendCouseComponent.vue'
import CoursePageMain from '../../organisms/CoursePage/CoursePageMain.vue'
import CourseSummaryInfo from '../../organisms/CoursePage/CourseSummaryInfo.vue'
import EnrollmentButtonComponent from '../../organisms/EnrollmentButtonComponent/EnrollmentButtonComponent.vue'
import AssignLearningPathCourseComponent from '../../organisms/LearningPath/AssignLearningPathCourseComponent.vue'
import CreateLearningPathComponent from '../../organisms/LearningPath/CreateLearningPathComponent.vue'
import PriceSummaryComponent from '../../organisms/PriceSummaryComponent/PriceSummaryComponent.vue'
import WishListToggleComponent from '../../organisms/WishListToggleComponent/WishListToggleComponent.vue'

export default {
    name: 'CoursePage',
    components: {
        CoursePageMain,
        EnrollmentButtonComponent,
        FeedbackStarsComponent,
        LoaderComponent,
        CourseSummaryInfo,
        CreateLearningPathComponent,
        PriceSummaryComponent,
        WishListToggleComponent,
        ContactTeaserComponent,
        AssignLearningPathCourseComponent,
        RecommendCourseComponent
    },
    perimeters: [featuresPerimeter, learningPathPerimeter, routePerimeter],
    data() {
        return {
            showEmptyIcon: true,
            showErrorMessage: false,
            isFetchingCourse: false,
            isFetchingCoursePrice: false,
            isFetchingActiveCourses: false,
            courseRating: null,
            showAssignLearningPath: false,
            showRecommendCourseModal: false,
            courseAssortmentId: null
        }
    },
    computed: {
        ...mapState('coursePageModule', ['course']),
        ...mapGetters('coursePageModule', ['defaultTab', 'cannotEnrollMessage']),
        ...mapGetters('accountModule', ['noaCompanyLicense']),
        ...mapGetters('configurationModule', ['currentEnvironment']),
        ...mapGetters('userModule', ['isUserImpersonating']),
        isLoading() {
            return this.isFetchingCourse || this.isFetchingActiveCourses
        },
        queryExternalId() {
            if (this.$route && this.$route.query) {
                return this.$route.query.startmoment
            }
            return null
        },
        isELearning() {
            return this.course?.learningMethod?.type?.toLowerCase() === courseLearningMethodTypes.SELFPLANNED
        },
        enrollmentButtonData() {
            return {
                id: this.course.id,
                startMoments: this.course.startMoments,
                externalId: this.course.externalId,
                courseName: this.course.name,
                providerName: this.course.embedded?.provider?.name,
                providerId: this.course.providerId,
                oleUrl: this.course.oleUrl,
                showZeroPrice: this.course.showZeroPrice,
                assortmentId: this.courseAssortmentId
            }
        }
    },
    mounted() {
        this.initialize()
    },
    destroyed() {
        this.RESET_STATE()
    },
    methods: {
        ...mapMutations('coursePageModule', ['SET_COURSE', 'SET_ASSORTMENT_ID', 'RESET_STATE']),
        ...mapActions('coursePageModule', ['getCoursePrice', 'checkUserAccess']),
        ...mapMutations('userModule', ['toggleUserCourseWishListItem']),
        async initialize() {
            const courseId = this.$route?.query?.course
            if (!courseId) {
                this.showErrorMessage = true
                return
            }

            this.courseAssortmentId = this.$route?.query?.assortment
            
            await this.SET_ASSORTMENT_ID(this.courseAssortmentId)
            await this.fetchCourse(courseId)
            
            if(!this.isUserImpersonating && this.$isAllowed('displayRecentlyVisitedCourses')) {
                postVisitedCourse(courseId, this.courseAssortmentId)
            }
        },
        closeAssignLearningPathModal() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    'course-page-tab': this.defaultTab
                },
                params: { ...this.$route.params }
            })
            this.showAssignLearningPath = false
        },
        async fetchCourse(courseId) {
            this.isFetchingCourse = true
            try {
                const course = await courseClient.getCourse(courseId)
                
                // check if course is not soft deleted
                if(course?.deleted) {
                    this.showErrorMessage = true
                    return
                }
                
                await this.SET_COURSE(course)
             
                if (course.oleUrl && course.redirectToOle) {
                    window.location.replace(course.oleUrl)
                }

                await this.checkUserAccess()
                this.fetchCoursePrice(courseId)
                this.getCourseRating(courseId)
            } catch (err) {
                this.showErrorMessage = true
                console.error(err)
            } finally {
                this.isFetchingCourse = false
            }
        },
        async getCourseRating(courseId) {
            try {
                const rating = await courseClient.getCourseRating(courseId)
                this.courseRating = rating
            } catch (error) {
                console.error(error)
            }
        },
        async fetchCoursePrice(courseId) {
            try {
                const coursePriceParams = {
                    courseId,
                    assortmentId: this.courseAssortmentId
                }

                if (this.$isAllowed("routeAsTeamleader")) {
                    coursePriceParams.useTeamLeaderAssortments = 'true'
                }

                await this.getCoursePrice(coursePriceParams)
            } catch (error) {
                throw new Error("Failed to fetch course price", { cause: error })
            }
        },
        updateUserWishList() {
            const newWishListItem = {
                $id: null,
                courseId: this.course.id,
                userId: null
            }
            this.toggleUserCourseWishListItem(newWishListItem)
        },
        toggleAssignLearningPath() {
            this.showAssignLearningPath = true
            this.$gtag.event('add_to_learning_path', {
                course_id: this.course.id,
                course_name: this.course.name,
                external_id: this.course.externalId
            })
        }
    }
}
</script>