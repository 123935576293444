<template>
  <div
    :class="{'is-open': isOpen}"
    class="c-searchsuggestions"
    data-test="search-suggestions"
  >
    <div
      class="d-md-flex justify-content-between"
      data-test="search-sugestions-justify-content-between"
    >
      <!-- Header Search suggestions -->
      <!-- Mobile/tablet -->
      <div
        v-click-outside="() => {isOpen = false}"
        class="c-searchsuggestions__small"
      >
        <div
          class="c-searchsuggestions__button"
          @click="toggle"
        >
          <div
            :class="{'show-close-icon': isOpen}"
            class="c-searchsuggestions__button-inner"
          ></div>
        </div>

        <div class="c-searchsuggestions__input-container">
          <search-input
            v-model="searchQuery"
            :placeholder="searchInputPlaceholderText"
            class="c-searchinput--header"
            @button-click="goToSearchResult"
            @keydown.native.enter="goToSearchResult"
          />
        </div>
      </div>

      <!-- Result page search suggestion -->
      <!-- Desktop -->
      <div
        class="c-searchsuggestions__large"
        data-test="search-suggestions-large"
      >
        <search-input
          v-model="searchQuery"
          :placeholder="searchInputPlaceholderText"
          @button-click="goToSearchResult"
          @keydown.native.enter="goToSearchResult"
        />

        <!-- TODO: Uncomment this autocomplete list, but also fix the bugs for it (OPLZ-13409 and OPLZ-...). 
                   And also enable the fetchSearchSuggestions again -->
        <autocomplete-list
          v-if="showAutocomplete"
          v-click-outside="closeSuggestions"
          class="d-none d-md-block"
          :suggestions="suggestions"
          :search-query="searchQuery"
          :show="showSuggestions"
          :show-close-button="true"
          :title="suggestionsTitle"
          button-title="Bekijk alle ontwikkelactiviteiten"
          @close="closeSuggestions"
          @itemSelected="suggestion => goToSuggestion(suggestion)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapState, mapGetters } from 'vuex'
import mixinClickOutside from '../../../mixins/click-outside-directive'
import AutocompleteList from '../../molecules/AutocompleteList/AutocompleteList.vue'
import courseClient from '../../../api/courseClient'
import SearchInput from '../../molecules/SearchInput/SearchInput.vue'

export default {
    name: 'SearchSuggestionsComponent',
    components: { AutocompleteList, SearchInput },
    mixins: [mixinClickOutside],
    props: {
        showAutocomplete: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isOpen: false,
            showSuggestions: false,
            searchQuery: '',
            suggestions: [],
            searchInputPlaceholderText: 'Zoek een ontwikkelactiviteit',
            suggestionsTitle: 'Suggesties'
        }
    },
    computed: {
        ...mapState('searchModule', ['query']),
        ...mapGetters('accountModule', ['firstStartMomentDate', 'finalStartMomentDate'])
    },
    watch: {
        searchQuery(newValue) {
            // Check if searchquery contains at least three characters
            if (newValue && newValue.length >= 3) {
                const suggestRequestData = {
                    query: newValue,
                    top: 5,
                    includeFirstStartMomentDateFilter: !!this.firstStartMomentDate,
                    includeFinalStartMomentDateFilter: !!this.finalStartMomentDate
                }
                courseClient
                    .fetchSearchSuggestions(suggestRequestData)
                    .then(response => {
                        this.suggestions = response.data.results

                        if (response.data.results.length) {
                            this.showSuggestions = true
                        } else {
                            this.closeSuggestions()
                        }
                    })
                    .catch(error => {
                        if (axios.isCancel(error)) {
                            // Do nothing
                        }
                    })
            } else {
                this.closeSuggestions()
            }
        }
    },
    mounted() {
        if(this.query) this.searchQuery = this.query
    },
    methods: {
        ...mapMutations('searchModule', ['removeFilters']),
        toggle() {
            this.isOpen = !this.isOpen
        },
        closeSuggestions() {
            this.showSuggestions = false
        },
        goToSearchResult() {
            // Cancel the latest request before routing to the results page
            courseClient.cancelFetchSearchSuggestions()

            this.removeFilters()

            // Trimming out spaces at the end of the search query is a temporary fix for bug NCOI2-3231
            const searchQueryTrimmed = this.searchQuery.replace(/\s*$/, '')
            // If within the team leader enrollment, should keep current route, it is the exception
            const routeName = (this.$route.path === '/inschrijven-teamleader' ? this.$route.name : 'search')
            const route = {
                name: routeName,
                query: {
                    ...this.$route.query,
                    q: searchQueryTrimmed
                }
            }
            // push analytics
            this.$gtag.event('search', { search_term: this.searchQuery })
            this.$router.push(route)
            this.closeSuggestions()
        },
        goToSuggestion(suggestion) {
            const productReference = suggestion?.productReference

            if (!productReference || productReference.indexOf(':') === -1) {
                return
            }

            const courseId = productReference.split(':')[1]
            const courseRoute = { name: 'course', query: { course: courseId, assortment: suggestion.assortmentId } }
            this.$router.push(courseRoute)

            this.closeSuggestions()

            if (courseRoute?.name === this.$route?.name) {
                this.$router.refreshPage()
            }
        }
    }
}
</script>
