<template>
  <div class="c-banner__text">
    <h1 class="c-banner__title u-textcolor-banner-text u-fw-bold">Hallo {{ user.firstName }}</h1>

    <h5
      class="c-banner__description u-textcolor-banner-text m-0 u-fw-regular u-position-relative"
    >
      <transition
        name="fade"
      >
        <recess-loader
          v-if="!isLoaded"
          color="primary"
          spinner-size="small"
        />
      </transition>
      Kies uit 
      <strong data-test="banner-course-count">{{ courseCount }}</strong> voor u geselecteerde ontwikkelactiviteiten van
      <strong>{{ providersCount }}</strong> aanbieders met een gemiddelde beoordeling van
      <strong>{{ ratingAverage }}</strong>
    </h5>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import courseClient from '../../../api/courseClient'

export default {
    data() {
        return {
            courseCount: 0,
            providersCount: 0,
            ratingAverage: 0,
            isLoaded: false
        }
    },
    computed: {
        ...mapState('userModule', ['user']),
        ...mapGetters('accountModule', ['firstStartMomentDate', 'finalStartMomentDate'])
    },    
    created() {
        this.fetchCounters()       
    },
    methods: {
        async fetchCounters() {
            try {
                const statistics =  await courseClient.getStatistics(!!this.firstStartMomentDate, !!this.finalStartMomentDate)

                this.courseCount = statistics?.courseTotalCount
                this.providersCount = statistics?.providersTotalCount
                this.ratingAverage = statistics?.ratingAverage 
            } catch (error) {
                throw new Error(error)
            } finally {
                this.isLoaded = true
            }
        }
        
    }
}
</script>