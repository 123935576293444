import flatten from "lodash/flatten"
import DateUtils from '../utils/DateUtils'
import orderStatus from '../constants/orderStatus'
import coursePriceHelper from "./coursePriceHelper"
import { INCOMPANY } from '../constants/productTypes'
import priceFormatter from './priceFormatter'


const showCapacityDetail = (showCapacityIconForAllCourses, productType) => showCapacityIconForAllCourses || productType === INCOMPANY

/**
 * Map events 
 * 
 * @param {Array} events 
 * @returns {Array} 
 */
const mapEvents = events => {
    const mappedEvents = events?.modules?.map(m => {
        return m.events?.map(e => {
            const eventDate = new Date(e.eventDate)
            const eventStartTime = DateUtils.formatTime(e.startTime)

            return { 
                ...e, 
                moduleName: m.name, 
                eventDate,
                eventDateDisplayDayNr: eventDate.getDate(),
                eventDateDisplayYear: eventDate.getFullYear(),
                displayStartTime: !!eventStartTime && eventStartTime !== '00:00' ? eventStartTime : null,
                displayEndTime: DateUtils.formatTime(e.endTime)
            }
        })
    })

    // Flat events array using lodash because Array.flat() is not supported by IE
    return flatten(mappedEvents)
}

/**
 * Map selected start moments with the choice index and text
 * 
 * @param {Object} selectedStartMoment the newly selected start moment
 * @param {Array} existingSelectedStartMoments already selected start moments
 * @returns {Object} mapped start moment with choice index and text
 */
const mapSelectedStartMoment = (selectedStartMoment, existingSelectedStartMoments) => {
    return { 
        ...selectedStartMoment,
        startMomentIndex: existingSelectedStartMoments?.length,
        choiceText: `${existingSelectedStartMoments?.length + 1}e keuze`
    }
}

/**
 * Get the capacity icon warning text
 * 
 * @param {Object} startMoment
 * @param {Boolean} limitIncompanyEnrollments
 * @param {String} productType
 * @param {Number} nrOfPeopleToEnroll
 * @returns {String}
 */
const getCapacityWarningText = (startMoment, limitIncompanyEnrollments, productType, nrOfPeopleToEnroll) => {
    const remainingSeats = startMoment?.maxParticipants - (startMoment?.pendingOrders + startMoment?.processedOrders)

    if (limitIncompanyEnrollments && 
      productType === INCOMPANY && 
      !startMoment?.hasReachedMaxCapacity && 
      (nrOfPeopleToEnroll && nrOfPeopleToEnroll > remainingSeats)) {
        return `Let op! U heeft ${ nrOfPeopleToEnroll } teamleden geselecteerd terwijl er voor dit startmoment maar ${ remainingSeats } plekken beschikbaar zijn.`
    }

    return null
}

/**
 * Map the start moment 
 * 
 * @param {Object} startMoment
 * @param {Boolean} limitIncompanyEnrollments
 * @param {String} productType
 * @param {Number} nrOfPeopleToEnroll
 * @returns {Object}
 */
const mapStartMoment = (startMoment, limitIncompanyEnrollments, productType, nrOfPeopleToEnroll) => {
    const blockFullyBookedIncompanyStartMoment = startMoment?.hasReachedMaxCapacity && limitIncompanyEnrollments && productType === INCOMPANY

    return {
        startMomentId: startMoment?.externalId,
        yearAndMonth: startMoment?.yearAndMonth,
        displayDate: DateUtils.formatDate(startMoment?.fullDate),
        displayTime: !!startMoment?.startTime && startMoment?.startTime !== '00:00' ? DateUtils.formatTime(startMoment.startTime) : null,
        firstEventStartDateTime: DateUtils.formatDateTime(startMoment?.startTime),
        firstEventEndDateTime: DateUtils.formatDateTime(startMoment?.endTime),
        city: startMoment?.city || '-',
        minParticipants: startMoment?.minParticipants || 0,
        maxParticipants: startMoment?.maxParticipants || 0,
        pendingOrders: startMoment?.pendingOrders || 0,
        processedOrders: startMoment?.processedOrders || 0,
        startGuaranteed: startMoment?.startGuaranteed,
        isValidStartMoment: !!startMoment,
        hasEvents: !!startMoment?.startTime,
        dayOfWeekDisplayValue: startMoment?.dayOfWeekDisplayValue,
        blockFullyBookedIncompanyStartMoment,
        price: priceFormatter.formatPrice(startMoment?.priceInfo?.totalPrice),
        priceInfo: startMoment?.priceInfo,
        capacityWarningText: getCapacityWarningText(startMoment, limitIncompanyEnrollments, productType, nrOfPeopleToEnroll),
        fullDate: startMoment?.fullDate,
        externalId: startMoment?.externalId
    }
}

/**
 * Map start moments
 * 
 * @param {Array} startMoments
 * @param {Boolean} limitIncompanyEnrollments
 * @param {String} productType
 * @param {Number} nrOfPeopleToEnroll
 * @returns {Array}
 */
const mapStartMoments = (startMoments, limitIncompanyEnrollments, productType, nrOfPeopleToEnroll) => {
    if (!startMoments || !startMoments.length) return []
  
    return startMoments.map(sm => mapStartMoment(sm, limitIncompanyEnrollments, productType, nrOfPeopleToEnroll))
}


/**
 * Gets the count of pending orders to be listed under capacity
 */
const GetPendingOrdersCount = (ordersForStartMoment) => {
    return ordersForStartMoment.filter(
        order => order.status === orderStatus.PendingAccount.codeName ||
            order.status === orderStatus.PendingProvider.codeName
    ).length
}

/**
 * Gets the count of processed and definite orders to be listed under capacity
 */
const GetProcessedOrdersCount = (ordersForStartMoment) => {
    return ordersForStartMoment.filter(
        order => order.status === orderStatus.AcceptedProvider.codeName ||
            order.status === orderStatus.PendingInvoice.codeName ||
            order.status === orderStatus.Invoiced.codeName ||
            order.status === orderStatus.NotInvoiced.codeName ||
            order.status === orderStatus.InvoicedByThirdParty.codeName ||
            order.status === orderStatus.Paid.codeName
    ).length
}

/**
 * Adds the pendingOrders and processedOrders to the start moment object
 * Expects a list of start moments, and a list of orders (corresponding with the start moment id's of course)
 * Output example: A list of start moments, including the properties pendingOrders and processedOrders
 */
const addOrderTotalsToStartMoment = (startMoment, orders) => {
    let hasReachedMaxCapacity = false
    let pendingOrders = 0
    let processedOrders = 0

    const ordersForStartMoment = orders?.filter(
        order => order.firstChoiceStartMomentId === startMoment.externalId
    )

    if(ordersForStartMoment?.length) {
        pendingOrders = GetPendingOrdersCount(ordersForStartMoment)
        processedOrders = GetProcessedOrdersCount(ordersForStartMoment)
        
        hasReachedMaxCapacity = startMoment?.maxParticipants 
            ? processedOrders + pendingOrders >= startMoment?.maxParticipants 
            : false
    }

    return {
        ...startMoment,
        pendingOrders,
        processedOrders,
        hasReachedMaxCapacity
    }
}

const addOrderTotalsToStartMoments = (startMoments, orders) => {
    if(!startMoments?.length || !orders?.length) return startMoments

    return startMoments?.map(startMoment => {
        return addOrderTotalsToStartMoment(startMoment, orders)
    })
}

/**
 * Gets the fallback date which can be filtered on.
 * This date is the month and year of the start date of the start moment.
 * Output example: Maart 2020
 */
const getFallbackDate = startMoment => {
    const fallbackMonth = startMoment?.startDate?.displayMonth
    const fallbackYear = startMoment?.startDate?.year
    const fallbackDisplayDate =
        fallbackMonth && fallbackYear ? `${fallbackMonth} ${fallbackYear}` : '-'
    return fallbackDisplayDate
}

/**
 * Gets the date which can be filtered on.
 * This date is the month and year of the first event of the start moment.
 * Output example: Maart 2020
 */
const getFilterDate = startMoment => {
    const firstEventDate = startMoment?.modules?.[0]?.events?.[0]?.eventDate
    const startYear = DateUtils.getYear(firstEventDate) || null
    const startMonth = startMoment?.modules?.[0]?.events?.[0]?.eventDateDisplayMonth
    const firstEventDisplayDate = startMonth && startYear ? `${startMonth} ${startYear}` : null

    return firstEventDisplayDate || getFallbackDate(startMoment)
}

/**
 * Gets the type of the start moment.
 * This returns the part of the day on which the classes are given (or weekend).
 * It also provides the day the classes are given if the first module has an event.
 * Output example: Avond (Vrijdag)
 */
const getVariant = startMoment => {
    const variant = startMoment?.displayType
    const day = startMoment?.modules?.[0]?.events?.[0]?.eventDateDisplayDay
    const displayDay = day ? `(${day})` : ''
    return variant ? `${variant} ${displayDay}` : '-'
}

/**
 * Gets the start date of the given start moment.
 * This returns the start date of the start moment.
 * If the exact day is not available, it will return the month and year of the start date.
 * Output example: 12-03-2020 or Maart 2020
 */
const getStartDate = startMoment => {
    const startDate = startMoment?.startDate?.displayDate || '-'
    return startDate
}

const getVatText = (displayVatInPrices = false) => {
    return coursePriceHelper.getVatText(displayVatInPrices)
}

const defaultTableHeader = (displayVatInPrices = false) => [
    {
        key: 'location',
        displayTitle: 'Locatie'
    },
    {
        key: 'displayDate',
        displayTitle: 'Startmoment'
    },
    {
        key: 'capacity',
        displayTitle: 'Bezetting'
    },
    {
        key: 'price',
        displayTitle: `Prijs (${getVatText(displayVatInPrices)})`
    }
]

const filterTableHeader = (tableHeader, showCapacity = false, displayPricingInformation = true) => {
    let filteredTableHeader = [...tableHeader]
    if (!showCapacity) {
        filteredTableHeader = tableHeader.filter(x => x.key !== 'capacity')
    }
    if (!displayPricingInformation) {
        filteredTableHeader = filteredTableHeader.filter(x => x.key !== 'price')
    }
    return filteredTableHeader
}

const getSelectedStartMomentsTableHeader = (showCapacity = false, displayVatInPrices = false, displayPricingInformation = true, startmomentUpdatesEnabled = true) => {
    let tableHeader = [
        {
            key: 'choiceText',
            displayTitle: 'Keuze'
        },
        ...defaultTableHeader(displayVatInPrices)
        
    ]

    if (startmomentUpdatesEnabled) {
        const changeLink = {
            key: 'change-link',
            slotKey: 'change-link'
        }
        tableHeader.push(changeLink)
    }

    tableHeader = filterTableHeader(tableHeader, showCapacity, displayPricingInformation)

    return tableHeader
}

const getEnrollmentStartMomentTableHeader = (showCapacity = false, displayVatInPrices = false, displayPricingInformation = true) => {
    let tableHeader = [
        ...defaultTableHeader(displayVatInPrices),
        {
            key: 'enroll-button',
            slotKey: 'enroll-button'
        }
    ]    
    
    tableHeader = filterTableHeader(tableHeader, showCapacity, displayPricingInformation)

    return tableHeader
}

const getStartMomentPlanningHeader = (showCapacity = false, displayVatInPrices = false, displayPricingInformation = true) => {
    let tableHeader = [
        ...defaultTableHeader(displayVatInPrices),
        {}
    ]

    tableHeader = filterTableHeader(tableHeader, showCapacity, displayPricingInformation)

    return tableHeader
}

export default {
    addOrderTotalsToStartMoments,
    getFilterDate,
    getFallbackDate,
    getVariant,
    getStartDate,
    getSelectedStartMomentsTableHeader,
    getEnrollmentStartMomentTableHeader,
    getStartMomentPlanningHeader,
    getVatText,
    mapStartMoments,
    mapStartMoment,
    mapSelectedStartMoment,
    addOrderTotalsToStartMoment,
    mapEvents,
    showCapacityDetail
}

