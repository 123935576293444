import { getOplzClient } from './apiClient'

const baseUrl = '/legal-agreements'

async function getLegalAgreements(consentRequired) {
    const client = await getOplzClient()
    const query = typeof consentRequired !== 'undefined' 
        ? `?consent-required=${!!consentRequired}` 
        : ''
    const response = await client.get(`${baseUrl}${query}`)
    return response?.data ?? null

}

export default {
    getLegalAgreements
}
